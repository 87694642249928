import { Auth, Hub } from 'aws-amplify'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { getDentrixProfile } from 'src/api/prime'

const FETCH_USER_DATA_INIT = 'FETCH_USER_DATA_INIT'
const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS'
const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE'
const SIGNOUT_USER = 'SIGNOUT_USER'
const LOGIN_ERROR = 'LOGIN_ERROR'
const SIGNUP_ERROR = 'SIGNUP_ERROR'
const SIGNUP_USER_DATA_SUCCESS = 'SIGNUP_USER_DATA_SUCCESS'
const SIGNUP_USER_DATA_FAILURE = 'SIGNUP_USER_DATA_FAILURE'
const SIGNUP_USER_CONFIRMATION_SUCCESS = 'SIGNUP_USER_CONFIRMATION_SUCCESS'
const SIGNUP_USER_CONFIRMATION_ERROR = 'SIGNUP_USER_CONFIRMATION_ERROR'
const SET_DENTRIX_ACCOUNT = 'SET_DENTRIX_ACCOUNT'

const amplifyAuthReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case FETCH_USER_DATA_INIT:
      return {
        ...state,
        signUpUser: null,
        isLoading: true,
        isError: false,
        errorMessage: null,
      }
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        signUpUser: null,
        user: payload.user,
        errorMessage: null,
      }
    case FETCH_USER_DATA_FAILURE:
      return {
        ...state,
        signUpUser: null,
        isLoading: false,
        isError: true,
        errorMessage: null,
      }
    case SIGNOUT_USER:
      return {
        ...state,
        signUpUser: null,
        user: null,
        errorMessage: null,
      }
    case LOGIN_ERROR:
    case SIGNUP_ERROR:
      return {
        ...state,
        signUpUser: null,
        errorMessage: payload.message,
      }
    case SIGNUP_USER_DATA_SUCCESS:
      return {
        ...state,
        user: null,
        signUpUser: payload,
      }
    case SIGNUP_USER_DATA_FAILURE:
      return {
        ...state,
        errorMessage: payload.message,
      }
    case SIGNUP_USER_CONFIRMATION_SUCCESS:
      return {
        ...state,
        user: null,
      }
    case SIGNUP_USER_CONFIRMATION_ERROR:
      return {
        ...state,
        errorMessage: payload.message,
      }
    case SET_DENTRIX_ACCOUNT:
      return {
        ...state,
        hasDentrixAccount: payload.hasDentrixAccount,
      }
  }
}

interface AuthState {
  isLoading: boolean
  isError: boolean
  errorMessage: string
  user: Record<string, any>
  signUpUser: Record<string, any>
  hasDentrixAccount: boolean
}

const initialState: AuthState = {
  isLoading: true,
  isError: false,
  errorMessage: null,
  user: null,
  signUpUser: null,
  hasDentrixAccount: null,
}

const useAuthenticator = () => {
  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState)
  const [triggerFetch, setTriggerFetch] = useState(false)

  const setHasDentrixAccount = useCallback(
    async (email: string) => {
      if (state.hasDentrixAccount !== null) return

      try {
        await getDentrixProfile(email)
        dispatch({
          type: SET_DENTRIX_ACCOUNT,
          payload: { hasDentrixAccount: true },
        })
      } catch {
        dispatch({
          type: SET_DENTRIX_ACCOUNT,
          payload: { hasDentrixAccount: false },
        })
      }
    },
    [state.hasDentrixAccount]
  )

  useEffect(() => {
    let isMounted = true

    const fetchUserData = async () => {
      if (isMounted) {
        dispatch({ type: FETCH_USER_DATA_INIT })
      }
      try {
        if (isMounted) {
          const data = await Auth.currentAuthenticatedUser()

          if (data) {
            dispatch({ type: FETCH_USER_DATA_SUCCESS, payload: { user: data } })
            setHasDentrixAccount(data.attributes.email)
          }
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: FETCH_USER_DATA_FAILURE })
        }
      }
    }

    const HubListener = () => {
      Hub.listen('auth', ({ payload }) => {
        onAuthEvent(payload)
      })
    }

    const onAuthEvent = (payload) => {
      switch (payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
          if (isMounted) {
            setTriggerFetch(true)
            // dispatch({ type: FETCH_USER_DATA_SUCCESS, payload: { user: { payload } } })
          }
          break
        case 'signUp':
          dispatch({ type: SIGNUP_USER_DATA_SUCCESS, payload: payload.data })
          return
        case 'signOut':
          dispatch({ type: SIGNOUT_USER })
          return
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          dispatch({ type: LOGIN_ERROR, payload: payload.data })
          return
        case 'signUp_failure':
          dispatch({ type: SIGNUP_ERROR, payload: payload.data })
          return
        default:
          return
      }
    }

    HubListener()
    fetchUserData()

    return () => {
      Hub.remove('auth', () => {
        console.log('auth removed')
      })
      isMounted = false
    }
  }, [triggerFetch, setHasDentrixAccount])

  const signOut = async () => {
    try {
      await Auth.signOut()
      setTriggerFetch(false)
    } catch (error) {
      console.log('error signing out user', error)
    }
  }

  const signIn = async ({
    username,
    password,
  }: {
    username: string
    password: string
  }) => {
    Auth.signIn(username, password)
  }

  const signInWithGoogle = async () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    })
  }

  const signUp = ({ password, email }) => {
    Auth.signUp({
      username: email,
      password,
      attributes: { email },
    })
  }

  const confirmSignUp = async ({ username, code }) => {
    try {
      const data = await Auth.confirmSignUp(username, String(code))
      if (data) {
        dispatch({
          type: SIGNUP_USER_CONFIRMATION_SUCCESS,
        })
      }
    } catch (error) {
      dispatch({ type: SIGNUP_USER_CONFIRMATION_ERROR, payload: error })
    }
  }

  return { state, signOut, signIn, signInWithGoogle, signUp, confirmSignUp }
}

export default useAuthenticator
