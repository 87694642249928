import { SvgIcon } from '@material-ui/core'

const BackArrow = (props) => (
  <SvgIcon width="18" height="12" {...props} viewBox="0 0 18 12" fill="none">
    <path
      d="M6.66669 1L1.66669 6L6.66669 11"
      stroke="#6C675C"
      strokeWidth="1.5"
    />
    <path d="M2.08337 6H17.9167" stroke="#6C675C" strokeWidth="1.5" />
  </SvgIcon>
)

export default BackArrow
