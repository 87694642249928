import { createStyles, LinearProgress, makeStyles } from '@material-ui/core'

interface Props {
  percentage: number
  isVisible: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
      top: '8px',
    },
  })
)

const ProgressBar = ({ isVisible = false, percentage = 0 }: Props) => {
  const classes = useStyles()

  return (
    isVisible && (
      <LinearProgress
        variant="determinate"
        value={percentage}
        className={classes.root}
      />
    )
  )
}

export default ProgressBar
