import { Controller, Control } from 'react-hook-form'
import { makeStyles, TextField, Theme } from '@material-ui/core'

interface TextInputProps {
  name: string
  control: Control<any>
  defaultValue?: string
  [key: string]: any
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
}))

const TextInput = ({
  name,
  control,
  defaultValue = '',
  ...other
}: TextInputProps) => {
  const classes = useStyles()

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field, formState: { errors } }) => (
        <TextField
          {...field}
          size="small"
          {...other}
          error={!!errors?.[name]}
          helperText={errors?.[name]?.message ?? ' '}
          className={classes.input}
        />
      )}
    />
  )
}

export default TextInput
