const background = -1
const standard = 1
const section = 10
const header = 100
const videoControls = 1000
const popper = 2000

const zIndexes = {
  background: background,
  standard: standard,
  section: section,
  header: header,
  videoControls: videoControls,
  popper: popper,
}

export default zIndexes
