import { callStripeTender } from '../prime/connector'

const getStripeCustomer = async (customerId: string) => {
  let customerPaymentInfo
  try {
    customerPaymentInfo = await callStripeTender('/stripe/payment-methods', {
      params: {
        customer: customerId,
        type: 'card',
      },
    })
    return customerPaymentInfo
  } catch (error) {
    return error
  }
}

export default getStripeCustomer
