import { InsuranceProvider } from '../../lib/types/Insurance'
import {
  Patient,
  PatientInfo,
  PatientListResponse,
} from '../../lib/types/Patient'
import { callApi } from './connector'

export const getStudios = async () => {
  const studiosList = await callApi('/api/studios', { method: 'GET' })
  return studiosList
}

export const createPatient = async (patientData: Patient) => {
  const data = await callApi('/api/crm/patients-v1', {
    method: 'POST',
    data: patientData,
  })
  return data
}

export const patchPatient = async (
  patientId: string,
  updateInfo: Record<string, unknown>
) => {
  const data = await callApi(`api/crm/patients-v1/${patientId}`, {
    method: 'PATCH',
    data: updateInfo,
  })

  return data
}

export const getInsuranceProviders = () => {
  return callApi('/api/insurance-providers', {
    method: 'GET',
  }) as Promise<InsuranceProvider[]>
}

interface PatientSearch {
  page: number
  page_size?: number
  exclude_unpublished?: boolean
  first_name?: string
  last_name?: string
  email?: string
  chart_number?: string
}

export const getPatientsList = ({
  page = 1,
  page_size = 15,
  exclude_unpublished = true,
  chart_number,
  email,
  first_name,
  last_name,
}: PatientSearch) => {
  return callApi('/api/crm/patients', {
    method: 'GET',
    params: {
      page,
      page_size,
      exclude_unpublished,
      chart_number,
      email,
      first_name,
      last_name,
    },
  }) as Promise<PatientListResponse>
}

export const setInsuranceProvider = (PatientInsurance: InsuranceProvider) => {
  return callApi('/api/crm/insurance', {
    method: 'POST',
    data: PatientInsurance,
  })
}

export const updatePatient = async (patientData: PatientInfo) => {
  return await callApi(`/api/crm/patients-v1/${patientData.id}`, {
    method: 'PUT',
    data: patientData,
  })
}

export const getOrthoImages = (patientId: string) => {
  return callApi(`/api/v2/patients/${patientId}/ortho-photo`, {
    method: 'GET',
  })
}

export const uploadOrthoImage = (patientData: PatientInfo, photo: File) => {
  const data = new FormData()
  data.append('photo', photo)
  return callApi(`/api/v2/patients/${patientData.id}/ortho-photo`, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
}

export const deleteOrthoImage = (patientData: PatientInfo, photoId: string) => {
  return callApi(`/api/v2/patients/${patientData.id}/ortho-photo/${photoId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getDentrixProfile = (email: string) => {
  return callApi(`/api/dentrix-user/${email}`, {
    method: 'GET',
  })
}
