import { callStripeTender } from '../prime/connector'

interface StoreCardParams {
  email?: string
  name?: string
  phone?: string
  customerId?: string
  paymentMethodId: string
}

const storeCardInStripe = async ({
  customerId,
  paymentMethodId,
  email,
  name,
  phone,
}: StoreCardParams) => {
  if (!customerId) return null

  const newPaymentMethod = await callStripeTender(
    `/stripe/payment-methods/${paymentMethodId}/attach`,
    {
      method: 'POST',
      data: {
        customer: customerId,
        email,
        name,
        phone,
      },
    }
  )
  return newPaymentMethod
}

export default storeCardInStripe
