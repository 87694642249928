import { CircularProgress, createStyles, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '200px'
    },
  }),
)

const Loader = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CircularProgress/>
    </div>
  )
}

export default Loader
