import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Title from 'src/components/base/Title'
import useTitle from 'src/hooks/useTitle'
import { PatientInfo } from 'src/lib/types/Patient'
import ImageUploading from 'react-images-uploading'
import {
  deleteOrthoImage,
  getOrthoImages,
  uploadOrthoImage,
} from 'src/api/prime'
import clsx from 'clsx'
import { downloadZip, formatDateAndTime } from 'src/utils'
import { Close } from '@material-ui/icons'
import { colors } from 'src/theme'
import useAuthenticator from 'src/hooks/useAuthenticator'

const useStyles = makeStyles((theme) => ({
  dndArea: {
    width: '100%',
    border: '1px dashed grey',
    marginBottom: theme.spacing(3),
    '&:hover': {
      color: colors.primary.blue,
      cursor: 'pointer',
    },
  },
  isDragging: {
    color: 'red',
    borderColor: 'red',
    fontWeight: 'bold',
  },
  border: {
    padding: '20px',
    background: 'white',
    borderRadius: '4px',
  },
  imageContainer: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    background: 'white',
    borderRadius: '4px',
    '&:before': {
      content: '""',
      paddingBottom: '56.6%',
      width: '100%',
      display: 'block',
    },
  },
  selected: {
    border: '2px solid #0068B5',
  },
  deleteIcon: {
    position: 'absolute',
    display: 'inline',
    top: '15px',
    right: '15px',
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image: {
    transform: 'none',
    left: 0,
    top: 0,
    height: '100%',
    objectFit: 'contain',
    width: '100%',
    position: 'absolute',
  },
  imageDescription: {
    padding: '4px',
    fontFamily: 'Founders Grotesk',
    fontSize: '16px',
    lineHeight: '65%',
    textTransform: 'uppercase',
    textAlign: 'right',
  },
  button: {
    marginRight: '15px',
  },
  buttonsContainer: {
    marginTop: '20px',
  },
}))

const PatientImages = () => {
  const [images, setImages] = useState([])
  const [selectedImages, setSelectedImages] = useState([])
  const location = useLocation()
  const history = useHistory()
  const { setTitle } = useTitle()
  const classes = useStyles()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [patientOrthoPhotos, setPatientOrthoPhotos] = useState([])
  const [patientInfo, setPatientInfo] = useState<PatientInfo>({
    person: { first_name: '', last_name: '' },
  })
  const {
    state: { hasDentrixAccount },
  } = useAuthenticator()

  const {
    person: { first_name, last_name },
  } = patientInfo
  const patientName = `${first_name} ${last_name}`

  const onChange = (imageList) => {
    setImages(() => imageList)
  }

  useEffect(() => {
    const handleImageUpload = async () => {
      setIsSubmitting(true)
      const uploadImagesPromiseArray = images.map((image) =>
        uploadOrthoImage(patientInfo, image.file)
      )
      Promise.all(uploadImagesPromiseArray)
        .then((data) => {
          setPatientOrthoPhotos((prev) => [...prev, ...data])
          setImages(() => [])
        })
        .catch((error) => console.log(error))
        .finally(() => setIsSubmitting(false))
    }

    if (images.length > 0) {
      handleImageUpload()
    }
  }, [images, patientInfo])

  useEffect(() => {
    setTitle(patientName)

    // eslint-disable-next-line
  }, [patientName])

  const toggleSelectedImage = (photo) => {
    photo.selected = !photo?.selected
    const hasBeenSelected = selectedImages.find((item) => item.id === photo.id)

    if (hasBeenSelected) {
      // remove from the list
      setSelectedImages((prev) => prev.filter((item) => item.id !== photo.id))
    } else {
      // add to the list
      setSelectedImages((prev) => [...prev, photo])
    }
  }

  const dowloadSelectedImages = () => {
    if (selectedImages.length > 0) {
      const filesURL = selectedImages.map((imageItem) => imageItem.signed_url)
      downloadZip(filesURL)
    }
  }

  const deletePhoto = (photo) => {
    setIsSubmitting(true)
    deleteOrthoImage(patientInfo, photo.id)
      .then((data) => {
        setPatientOrthoPhotos((prev) =>
          prev.filter((item) => photo.id !== item.id)
        )
      })
      .catch((error) => console.log(error))
      .finally(() => setIsSubmitting(false))
  }

  useEffect(() => {
    const getPatientOrthoImages = async () => {
      const photos = await getOrthoImages(patientInfo.id)
      setPatientOrthoPhotos(photos)
    }

    if (!patientInfo?.id) {
      return
    }

    getPatientOrthoImages()
  }, [patientInfo?.id])

  useEffect(() => {
    if (location?.state) {
      setPatientInfo(location?.state['patientInfo'] as PatientInfo)
    } else {
      history.push('/search')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (hasDentrixAccount === false) {
      history.push('/search')
    }
    // eslint-disable-next-line
  }, [hasDentrixAccount])

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12} md={10}>
        <Title>Images</Title>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={dowloadSelectedImages}
          disabled={selectedImages.length === 0}>
          Download
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <Grid container spacing={3} alignItems="center">
          {patientOrthoPhotos.map((photo, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              style={{ position: 'relative' }}>
              <div
                className={clsx(classes.border, {
                  [classes.selected]: photo.selected,
                })}>
                <span
                  className={classes.deleteIcon}
                  onClick={() => deletePhoto(photo)}>
                  <Close />
                </span>
                <div
                  className={classes.imageContainer}
                  onClick={() => toggleSelectedImage(photo)}>
                  <img
                    src={`${photo.signed_url}`}
                    srcSet={`${photo.signed_url}`}
                    alt={photo.created_at}
                    loading="lazy"
                    className={classes.image}
                  />
                </div>
              </div>
              <Typography className={classes.imageDescription}>
                Uploaded On {formatDateAndTime(photo.created_at)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        dataURLKey="photo"
        acceptType={['jpg', 'jpeg', 'png']}>
        {({ onImageUpload, isDragging, dragProps }) => (
          <>
            <Grid item xs={12} sm={12} md={10}>
              <Box
                component="button"
                className={clsx(classes.dndArea, {
                  [classes.isDragging]: isDragging,
                })}
                sx={{ p: 4 }}
                onClick={onImageUpload}
                {...dragProps}>
                Drag your Photos
              </Box>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={onImageUpload}
                disabled={isSubmitting}>
                Upload
              </Button>
            </Grid>
          </>
        )}
      </ImageUploading>
    </Grid>
  )
}

export default PatientImages
