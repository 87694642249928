import { callStripeTender } from '../prime/connector'

const getChargesByCustomerId = async (
  stripeCustomerId: string,
  limit: number
) => {
  const customer = await callStripeTender('/stripe/customers/charges', {
    method: 'POST',
    data: {
      customer_id: stripeCustomerId,
      limit,
    },
  })

  return customer
}

export default getChargesByCustomerId
