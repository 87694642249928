export const CHARGE_OPTIONS = {
  categories: ['services', 'fee'],
  description: {
    services: [
      'Checkup',
      'Dental Treatment',
      'Ortho Treatment',
      'Other Service',
    ],
    fee: ['No Show Fee', 'Cancellation Fee', 'Other Fee'],
  },
}
