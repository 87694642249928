import { createContext, useContext, useReducer } from 'react'
import { getInsuranceProviders, getStudios } from 'src/api/prime'
import { InsuranceProvider } from 'src/lib/types/Insurance'
import { PatientInfo } from 'src/lib/types/Patient'
import { Studio } from 'src/lib/types/Studio'
import { sortStudiosByName } from 'src/utils'

export const SET_PATIENT = 'SET_PATIENT'
export const REMOVE_PATIENT = 'REMOVE_PATIENT'
export const SET_INSURANCE_PROVIDERS = 'SET_INSURANCE_PROVIDERS'
export const GET_INSURANCE_PROVIDERS = 'GET_INSURANCE_PROVIDERS'
export const SET_STUDIOS = 'SET_STUDIOS'
export const GET_STUDIOS = 'GET_STUDIOS'
export const SET_PROGRESS_BAR = 'SET_PROGRESS_BAR'
export const HIDE_PROGRESS_BAR = 'HIDE_PROGRESS_BAR'
export const SET_CUSTOM_TITLE = 'SET_CUSTOM_TITLE'
export const HIDE_CUSTOM_TITLE = 'HIDE_CUSTOM_TITLE'

interface ProgressBar {
  progress: number
  isVisible: boolean
}

type Action =
  | { type: typeof SET_PATIENT; payload: PatientInfo }
  | { type: typeof REMOVE_PATIENT }
  | { type: typeof SET_INSURANCE_PROVIDERS; payload: InsuranceProvider[] }
  | { type: typeof GET_INSURANCE_PROVIDERS }
  | { type: typeof GET_STUDIOS }
  | { type: typeof SET_STUDIOS; payload: Studio[] }
  | { type: typeof SET_PROGRESS_BAR; payload: ProgressBar }
  | { type: typeof HIDE_PROGRESS_BAR }
  | {
      type: typeof SET_CUSTOM_TITLE
      payload: { title: string; backPath: string }
    }
  | { type: typeof HIDE_CUSTOM_TITLE }

export type BaseContext = {
  currentPatient: PatientInfo | null
  studiosList: Studio[] | null
  insuranceProvidersList: InsuranceProvider[] | null
  progressBar: ProgressBar
  customTitle: string | null
  backPath: string | null
}

export type Dispatch = (action: Action) => void
export type ProviderProps = { children: React.ReactNode }

const globalContextReducer = (state: BaseContext, action: Action) => {
  switch (action.type) {
    case SET_PATIENT: {
      return { ...state, currentPatient: action.payload }
    }
    case REMOVE_PATIENT: {
      return { ...state, currentPatient: null }
    }
    case SET_INSURANCE_PROVIDERS: {
      return { ...state, insuranceProvidersList: action.payload }
    }
    case GET_INSURANCE_PROVIDERS: {
      return { ...state }
    }
    case GET_STUDIOS: {
      return { ...state }
    }
    case SET_STUDIOS: {
      return { ...state, studiosList: action.payload }
    }
    case SET_PROGRESS_BAR: {
      return { ...state, progressBar: action.payload }
    }
    case HIDE_PROGRESS_BAR: {
      return { ...state, progressBar: { isVisible: false, progress: 0 } }
    }
    case SET_CUSTOM_TITLE: {
      return {
        ...state,
        customTitle: action.payload.title,
        backPath: action.payload.backPath,
      }
    }
    case HIDE_CUSTOM_TITLE: {
      return { ...state, customTitle: null, backPath: null }
    }
  }
}

const fetchInsuranceProviders = (dispatch: Dispatch) => async () => {
  dispatch({ type: GET_INSURANCE_PROVIDERS })
  try {
    const insuranceProviders = await getInsuranceProviders()
    dispatch({ type: SET_INSURANCE_PROVIDERS, payload: insuranceProviders })
  } catch (_error) {
    //TODO handle error case
  }
}

const fetchStudios = (dispatch: Dispatch) => async () => {
  dispatch({ type: GET_STUDIOS })
  try {
    const studiosList = await getStudios()
    dispatch({ type: SET_STUDIOS, payload: sortStudiosByName(studiosList) })
  } catch (_error) {
    //TODO handle error case
  }
}

const setProgressBar = (dispatch: Dispatch) => (progress: number) => {
  dispatch({
    type: SET_PROGRESS_BAR,
    payload: { progress, isVisible: true },
  })
}

const hideProgressBar = (dispatch: Dispatch) => () => {
  dispatch({ type: HIDE_PROGRESS_BAR })
}

const setTitle =
  (dispatch: Dispatch) => (title: string, backPath?: string | null) => {
    dispatch({
      type: SET_CUSTOM_TITLE,
      payload: { title, backPath },
    })
  }

const hideTitle = (dispatch: Dispatch) => () => {
  dispatch({ type: HIDE_CUSTOM_TITLE })
}

const initialContext: BaseContext = {
  currentPatient: null,
  studiosList: null,
  insuranceProvidersList: null,
  progressBar: { isVisible: false, progress: 0 },
  customTitle: null,
  backPath: null,
}

const GlobalContext = createContext<
  | {
      state: BaseContext
      dispatch: Dispatch
      fetchInsuranceProviders: () => void
      fetchStudios: () => void
      setProgressBar: (progress: number) => void
      hideProgressBar: () => void
      setTitle: (title: string) => void
      hideTitle: () => void
    }
  | undefined
>(undefined)

const GlobalContextProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(globalContextReducer, initialContext)

  const value = {
    state,
    dispatch,
    fetchInsuranceProviders: fetchInsuranceProviders(dispatch),
    fetchStudios: fetchStudios(dispatch),
    setProgressBar: setProgressBar(dispatch),
    hideProgressBar: hideProgressBar(dispatch),
    setTitle: setTitle(dispatch),
    hideTitle: hideTitle(dispatch),
  }
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

const useGlobalContext = () => {
  const context = useContext(GlobalContext)

  if (context === undefined) {
    throw new Error(
      'useGlobalContext must be used within a GlobalContextProvider'
    )
  }
  return context
}

export { GlobalContextProvider, useGlobalContext }
