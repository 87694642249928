import { SvgIcon } from '@material-ui/core'

const AmexIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 29">
    <path
      d="M2.49997 0H37.5C38.8801 0 40 1.11995 40 2.5012V26.2512C40 27.6312 38.8801 28.7512 37.5 28.7512H2.49997C1.11871 28.7513 0 27.6312 0 26.2514V2.5012C0 1.11995 1.11995 0 2.49997 0Z"
      fill="#26A6D1"
    />
    <path
      d="M6.45004 10L2.5 18.7413H7.22875L7.81498 17.3476H9.15497L9.74119 18.7413H14.9462V17.6776L15.41 18.7413H18.1025L18.5663 17.6551V18.7413H29.3913L30.7076 17.3838L31.9401 18.7413L37.5001 18.7526L33.5375 14.3951L37.5001 10H32.0263L30.745 11.3324L29.5513 10H17.7751L16.7639 12.2562L15.7289 10H11.01V11.0275L10.4851 10C10.4851 10 6.45004 10 6.45004 10ZM7.36503 11.2413H9.67004L12.2901 17.1688V11.2413H14.8152L16.8388 15.4913L18.7039 11.2413H21.2164V17.5137H19.6876L19.6751 12.5987L17.4463 17.5137H16.0788L13.8375 12.5987V17.5137H10.6925L10.0963 16.1075H6.87504L6.28004 17.5125H4.59498L7.36503 11.2413ZM22.615 11.2413H28.8313L30.7325 13.295L32.6951 11.2413H34.593L31.7076 14.3938L34.5963 17.51H32.6089L30.7076 15.4325L28.735 17.51H22.615V11.2413ZM8.48634 12.3025L7.42508 14.8076H9.54638L8.48634 12.3025ZM24.1501 12.54V13.685H27.5413V14.9613H24.1501V16.2113H27.9539L29.7213 14.3701L28.0289 12.5389H24.1501V12.54Z"
      fill="white"
    />
  </SvgIcon>
)

export default AmexIcon
