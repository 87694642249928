import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, makeStyles } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextInput from '../formBase/TextInput'
import { Patient } from '../../lib/types/Patient'
import SelectInput from '../formBase/SelectInput'
import { Studio } from '../../lib/types/Studio'
import PhoneInput from '../formBase/PhoneInput'
import { maxCharacterLengthValidationErrorMessage } from 'src/utils'

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
}))

const schema: yup.SchemaOf<Omit<Omit<Patient, 'stripe_customer_id'>, 'phone'>> =
  yup.object().shape({
    first_name: yup
      .string()
      .max(25, maxCharacterLengthValidationErrorMessage(25))
      .required(),
    last_name: yup
      .string()
      .max(25, maxCharacterLengthValidationErrorMessage(25))
      .required(),
    email: yup
      .string()
      .email()
      .max(60, maxCharacterLengthValidationErrorMessage(60))
      .required(),
    primary_phone: yup.string().required(),
    location_id: yup.string().required(), // TODO we can update schema to take only specific data but maybe it is redundant
    preferred_name: yup
      .string()
      .max(25, maxCharacterLengthValidationErrorMessage(25)),
  })

interface AddPatientFormProps {
  studios: Studio[]
  onSubmit: (data: Patient) => void
}

const AddPatientForm = ({ studios, onSubmit }: AddPatientFormProps) => {
  const classes = useStyles()

  const [studioOptions] = useState(
    studios.map(({ id, name: label }) => ({ id, label }))
  )

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<Patient>({
    defaultValues: {},
    resolver: yupResolver(schema),
  })

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id="firstName"
          name="first_name"
          control={control}
          label="First name"
          fullWidth
          className={classes.input}
          disabled={isSubmitting}
        />
        <TextInput
          id="lastName"
          name="last_name"
          control={control}
          label="Last name"
          fullWidth
          className={classes.input}
          disabled={isSubmitting}
        />
        <TextInput
          id="preferred_name"
          name="preferred_name"
          control={control}
          label="Preferred name"
          fullWidth
          className={classes.input}
          disabled={isSubmitting}
        />
        <TextInput
          id="email"
          name="email"
          control={control}
          label="Email"
          fullWidth
          className={classes.input}
          disabled={isSubmitting}
        />
        <PhoneInput
          control={control}
          name="primary_phone"
          label="Phone"
          id="primary_phone"
          errors={errors}
          placeholder="Phone number"
          className={classes.input}
          autoComplete="tel-national"
          fullWidth
          disabled={isSubmitting}
        />

        <SelectInput
          id="preferredStudioId"
          name="location_id"
          defaultValue=""
          control={control}
          label="Studio"
          fullWidth
          options={studioOptions}
          className={classes.input}
          disabled={isSubmitting}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting}>
          Create Member
        </Button>
      </form>
    </div>
  )
}

export default AddPatientForm
