import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Title from 'src/components/base/Title'
import TextInput from 'src/components/formBase/TextInput'
import { PatientInfo } from 'src/lib/types/Patient'
import { FilterList, Search as SearchIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { Eyebrow } from 'src/components/base'
import { colors } from 'src/theme'
import { getPatientsList } from 'src/api/prime'
import RadioGroup from 'src/components/formBase/RadioGroup'
import Loader from 'src/components/Loader'
import { phoneFormater } from 'src/utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    search: {
      position: 'relative',
      width: '100%',
    },
    searchHeader: {
      display: 'flex',
      flexFlow: 'column',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        flexFlow: 'row',
        columnGap: '15px',
      },
    },
    searchBox: {
      position: 'relative',
      width: '100%',
    },
    searchFilters: {
      marginBottom: theme.spacing(2),
    },
    iconButton: {
      border: 0,
      background: 'none',
      position: 'absolute',
      top: '20px',
      right: '10px',
      '&:hover': {
        cursor: 'pointer',
      },
      '& > svg': {
        fill: colors.primary.brown[0],
      },
    },
    searchBtn: {
      borderRadius: '5px',
      padding: '2px 25px',
      height: '60px',
    },
    searchContainer: {
      width: '100%',
      top: '50px',
    },
    searchList: {
      listStyle: 'none',
      padding: '0',
      margin: '0',
    },
    searchItem: {
      background: colors.tints.blue[4],
      color: 'white',
      padding: '16px 16px 8px',
      '&:hover': {
        background: colors.primary.grey[0],
        color: 'black',
        '& p, a': {
          color: colors.primary.copper[1],
        },
      },
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
      },
    },
    infoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      '& div': {
        maxWidth: '200px',
        overflow: 'hidden',
      },
    },
    contactInfo: {
      textAlign: 'right',
    },
  })
)

const PatientSearchList = ({
  patientsList,
}: {
  patientsList: PatientInfo[]
}) => {
  const classes = useStyles()

  if (patientsList.length === 0) {
    return (
      <div>
        <Typography variant="body1">There are no patients to show</Typography>
      </div>
    )
  }

  return (
    <ul className={classes.searchList}>
      {patientsList &&
        patientsList.map((patientInfo) => {
          const { email, first_name, last_name, primary_phone } =
            patientInfo.person
          const { chart_number, preferred_studio } = patientInfo

          return (
            <li className={classes.searchItem} key={patientInfo.id}>
              <div>
                <Link
                  to={{
                    pathname: `/patients/${patientInfo.chart_number}`,
                    state: { patientInfo },
                  }}
                >
                  <Typography variant="body1">
                    {first_name} {last_name}
                  </Typography>
                  <div className={classes.infoContainer}>
                    <div>
                      <Eyebrow>{phoneFormater(primary_phone)}</Eyebrow>
                      <Eyebrow>{preferred_studio}</Eyebrow>
                    </div>
                    <div className={classes.contactInfo}>
                      <Eyebrow> {email}</Eyebrow>
                      <Eyebrow>{chart_number}</Eyebrow>
                    </div>
                  </div>
                </Link>
              </div>
            </li>
          )
        })}
    </ul>
  )
}

const Search = () => {
  const classes = useStyles()
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      searchBy: 'chart_number',
    },
  })

  const [search, setSearch] = useState([])
  const [showAdvanceSearch, setShowAdvanceSearch] = useState<boolean>(false)

  const searchBy = watch('searchBy')
  const searchLabel = searchBy
    ? `Search by ${searchBy.replace('_', ' ')}`
    : 'Search'

  const onSubmit = async ({ search }) => {
    try {
      const searchParams = { page: 1 }
      searchParams[searchBy] = search

      const response = await getPatientsList(searchParams)
      setSearch(response.results)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleAdvanceSearch = () => {
    setShowAdvanceSearch((currentValue) => !currentValue)
  }

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12} md={10}>
        <Title>Search members</Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.search}>
            <div className={classes.searchHeader}>
              <div className={classes.searchBox}>
                <TextInput
                  fullWidth
                  label={searchLabel}
                  name="search"
                  control={control}
                ></TextInput>
                <button type="submit" className={classes.iconButton}>
                  <SearchIcon />
                </button>
              </div>
              <Button
                type="button"
                className={classes.searchBtn}
                onClick={toggleAdvanceSearch}
                variant="contained"
                startIcon={<FilterList />}
              >
                Advanced
              </Button>
            </div>
            {showAdvanceSearch && (
              <div className={classes.searchFilters}>
                <RadioGroup
                  name="searchBy"
                  control={control}
                  defaultValue="chart_number"
                  label="filter by"
                  size="small"
                  row={true}
                  options={[
                    { value: 'chart_number', label: 'Chart Number' },
                    { value: 'email', label: 'Email' },
                    { value: 'first_name', label: 'First Name' },
                    { value: 'last_name', label: 'Last Name' },
                  ]}
                  parse={(value: string): string => value}
                />
              </div>
            )}
            <div className={classes.searchContainer}>
              {isSubmitting ? (
                <Loader />
              ) : (
                <PatientSearchList patientsList={search} />
              )}
            </div>
          </div>
        </form>
      </Grid>
    </Grid>
  )
}

export default Search
