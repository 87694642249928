import { callStripeTender } from '../prime/connector'

export const setupIntents = async () => {
  const response = await callStripeTender('/stripe/setup-intents', {
    method: 'POST',
  })
  return response
}

export const paymentIntents = async (
  amount: number,
  customerId: string,
  metadata?: Record<string, string | boolean>
) => {
  try {
    const response = await callStripeTender('/stripe/payment-intents', {
      method: 'POST',
      data: {
        amount,
        customer: customerId,
        metadata,
      },
    })

    return response
  } catch (error) {
    return error
  }
}
