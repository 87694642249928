import { SvgIconProps } from '@material-ui/core'
import { FC } from 'react'
import {
  AmexIcon,
  CreditCardIcon,
  DinersIcon,
  DiscoverIcon,
  JcbIcon,
  UnionpayIcon,
  VisaIcon,
} from '../icons'
import MastercardIcon from '../icons/MasterCard'

interface Props extends SvgIconProps {
  brand: string
}

const CreditCardLogo: FC<Props> = ({ brand, ...other }) => {
  const brandIcons = {
    amex: AmexIcon,
    diners: DinersIcon,
    discover: DiscoverIcon,
    jcb: JcbIcon,
    mastercard: MastercardIcon,
    unionpay: UnionpayIcon,
    visa: VisaIcon,
  }

  const Component = brandIcons[brand] ? brandIcons[brand] : CreditCardIcon

  return <Component fontSize="inherit" {...other} />
}

export default CreditCardLogo
