import {
  AppBar,
  createStyles,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
} from '@material-ui/core'
import {
  ChevronLeft,
  Dashboard as DashboardIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
  AddCircle as AddPatientIcon,
} from '@material-ui/icons'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useGlobalContext } from 'src/context'
import BackArrow from '../icons/BackArrow'
import ProgressBar from '../ProgressBar'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      width: 240,
    },
    sidebarLink: {
      textDecoration: 'none',
    },
    sidebarText: {
      marginLeft: '10px',
    },
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    backBtnContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      px: [1],
    },
    toolBar: {
      justifyContent: 'space-between',
    },
  })
)

const isCrmEnabled = process.env.REACT_APP_ENABLE_EMERGENCY_CRM === 'true'

const NavigationItems = (classes, closeSideBar) => (
  <div className={classes.list}>
    <List>
      <ListItem button>
        <Link to="/" className={classes.sidebarLink} onClick={closeSideBar}>
          <ListItemIcon>
            <DashboardIcon />
            <ListItemText primary="Dashboard" className={classes.sidebarText} />
          </ListItemIcon>
        </Link>
      </ListItem>
      <ListItem button>
        <Link
          to="/search"
          className={classes.sidebarLink}
          onClick={closeSideBar}
        >
          <ListItemIcon>
            <SearchIcon />
            <ListItemText primary="Search" className={classes.sidebarText} />
          </ListItemIcon>
        </Link>
      </ListItem>
      {isCrmEnabled && (
        <ListItem button>
          <Link
            to="/add-patient"
            className={classes.sidebarLink}
            onClick={closeSideBar}
          >
            <ListItemIcon>
              <AddPatientIcon />
              <ListItemText
                primary="Add Patient"
                className={classes.sidebarText}
              />
            </ListItemIcon>
          </Link>
        </ListItem>
      )}
    </List>
  </div>
)

const NavBar = ({
  signOut,
  isLogIn,
}: {
  signOut: () => void
  isLogIn: boolean
}) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const {
    state: {
      progressBar: { isVisible, progress },
      customTitle,
    },
  } = useGlobalContext()
  let history = useHistory()

  const closeSideBar = () => {
    setIsOpen(false)
  }

  const openSideBar = () => {
    setIsOpen(true)
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={openSideBar}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            {customTitle ? (
              <>
                <Button onClick={() => history.goBack()}>
                  <BackArrow />
                </Button>
                {customTitle}
              </>
            ) : (
              'Offline Tend Tool'
            )}
          </Typography>

          {isLogIn && (
            <Button onClick={signOut} color="inherit">
              Log Out
            </Button>
          )}
        </Toolbar>
        <ProgressBar isVisible={isVisible} percentage={progress} />
      </AppBar>
      <Drawer anchor="left" open={isOpen} onClose={closeSideBar}>
        <Toolbar className={classes.backBtnContainer}>
          <IconButton onClick={closeSideBar}>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        <Divider />
        {NavigationItems(classes, closeSideBar)}
      </Drawer>
    </>
  )
}

export default NavBar
