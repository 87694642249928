import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { Subtitle } from 'src/components/base'
import Title from 'src/components/base/Title'
import RadioGroup from 'src/components/formBase/RadioGroup'
import Loader from 'src/components/Loader'
import useStripeApi from 'src/hooks/useStripe'
import useTitle from 'src/hooks/useTitle'
import { PatientInfo } from 'src/lib/types/Patient'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: { marginBottom: theme.spacing(2) },
    radioGroup: { marginBottom: theme.spacing(2) },
    button: {
      marginBottom: theme.spacing(1),
    },
  })
)

const PaymentList = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const { setTitle } = useTitle()

  let patientInfo: PatientInfo

  if (!location.state) {
    history.push('/patients')
  }

  patientInfo = location.state['patientInfo'] as PatientInfo

  const { patientStripeInfo } = useStripeApi(
    patientInfo.person.stripe_customer_id
  )

  const patientName = `${patientInfo.person.first_name} ${patientInfo.person.last_name}`
  const hasCreditCard = patientStripeInfo?.length !== 0 ? true : false

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      cardSelected: '',
    },
  })

  const currentCardSelected = watch('cardSelected')

  const onSubmit = ({ cardSelected }) => {
    if (cardSelected) {
      history.push(`/patients/${patientInfo.chart_number}/create-payment`, {
        patientInfo,
        patientStripeInfo,
        cardSelected,
      })
    }
  }

  const goToAddCard = () => {
    history.push(`/patients/${patientInfo.chart_number}/add-card`, {
      patientInfo,
    })
  }

  useEffect(() => {
    setTitle(patientName)
    // eslint-disable-next-line
  }, [patientName])

  if (!patientStripeInfo) {
    return <Loader />
  }

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={6} md={8}>
        <div>
          <Title>Manage/Charge Cards</Title>
          {hasCreditCard ? (
            <div className={classes.formContainer}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <RadioGroup
                  name="cardSelected"
                  control={control}
                  label="Credit Card List"
                  row={false}
                  options={patientStripeInfo.map((cardInfo) => {
                    const { brand, last4, exp_month, exp_year } = cardInfo.card
                    return {
                      value: JSON.stringify(cardInfo),
                      label: `${brand}: ${last4} - ${exp_month}/ ${exp_year}`,
                    }
                  })}
                  parse={(value: string): string => value}
                  className={classes.radioGroup}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  disabled={isSubmitting || currentCardSelected === ''}
                >
                  Next
                </Button>
              </form>
            </div>
          ) : (
            <Subtitle>
              This patient doesn't have any credit card record
            </Subtitle>
          )}
          <div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={goToAddCard}
              fullWidth
            >
              Add Credit Card
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default PaymentList
