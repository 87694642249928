export const nantes = {
  fontFamily: 'Nantes',
  fontDisplay: 'fallback',
  fontWeight: 'normal',
  src: `
    url('/fonts/nantes/NantesWeb-Regular.woff2') format('woff2'),
    url('/fonts/nantes/NantesWeb-Regular.woff') format('woff')
  `,
}

export const nantesBold = {
  fontFamily: 'Nantes',
  fontDisplay: 'fallback',
  fontWeight: 'bold',
  src: `
    url('/fonts/nantes/NantesWeb-Bold.woff2') format('woff2'),
    url('/fonts/nantes/NantesWeb-Bold.woff') format('woff')
  `,
}

export const foundersGrotesk = {
  fontFamily: 'Founders Grotesk',
  fontDisplay: 'fallback',
  fontWeight: 'normal',
  src: `
    url('/fonts/founders-grotesk/FoundersGroteskWeb-Regular.woff2') format('woff2'),
    url('/fonts/founders-grotesk/FoundersGroteskWeb-Regular.woff') format('woff')
  `,
}

export const foundersMedium = {
  fontFamily: 'Founders Grotesk',
  fontDisplay: 'fallback',
  fontWeight: 500,
  src: `
    url('/fonts/founders-grotesk/FoundersGroteskWeb-Medium.woff2') format('woff2'),
    url('/fonts/founders-grotesk/FoundersGroteskWeb-Medium.woff') format('woff')
  `,
}

const fonts = { nantes, nantesBold, foundersGrotesk, foundersMedium }
export default fonts
