import axios from 'axios'
import { ServiceCode } from 'src/lib/types/Services'
import { callApi } from './connector'

export interface FetchAvailabilities {
  studio: string
  service: ServiceCode
  patient_id?: string
  operatory_id?: string
  provider_id?: string
  patient_type?: string
}

interface DateAvalabilities {
  aggregateStart: Date
  start: Date
  end: Date
}

interface Appoinment {
  starts_at: string
  ends_at: string
  operatory_id: string
  studio: string
  service_type: string
  patient_id: string
  provider_id: string
}

const source = axios.CancelToken.source()

const serializeDate = ({ aggregateStart, start, end }: DateAvalabilities) => {
  return {
    aggregateStart: aggregateStart.toISOString(),
    start: start.toISOString(),
    end: end.toISOString(),
  }
}

export const fetchAvailabilities = (
  date: DateAvalabilities,
  params: FetchAvailabilities
) => {
  const formatedDate = serializeDate(date)

  return callApi('/api/availabilities', {
    method: 'GET',

    params: {
      starts_at: formatedDate.start,
      ends_at: formatedDate.end,
      aggregate_requests_starts_at: formatedDate.aggregateStart,
      ...params,
    },
    cancelToken: source.token,
  })
}

export const fetchNextAvailableDate = (
  date: DateAvalabilities,
  params: FetchAvailabilities
) => {
  const formatedDate = serializeDate(date)

  return callApi('/api/availabilities/next_available', {
    method: 'GET',
    params: {
      starts_at: formatedDate.start,
      ends_at: formatedDate.end,
      aggregate_requests_starts_at: formatedDate.aggregateStart,
      ...params,
    },
    cancelToken: source.token,
  })
}

export const scheduleAnAppoinment = (appointment: Appoinment) => {
  return callApi('/api/appointments', {
    method: 'POST',
    data: {
      ...appointment,
    },
    cancelToken: source.token,
  })
}
