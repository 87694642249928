import { SvgIcon } from '@material-ui/core'

const CreditCardIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 17">
    <path
      opacity="0.5"
      d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V11H0V2Z"
      fill="#6C675C"
    />
    <path
      opacity="0.5"
      d="M24 15C24 16.1046 23.1046 17 22 17L2 17C0.89543 17 7.8281e-08 16.1046 1.74846e-07 15L3.49691e-07 13L24 13L24 15Z"
      fill="#6C675C"
    />
  </SvgIcon>
)

export default CreditCardIcon
