import { SvgIcon } from '@material-ui/core'

const DiscoverIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 29">
    <path
      d="M2.49997 0H37.5C38.8801 0 40 1.11871 40 2.49997V26.2501C40 27.6301 38.8801 28.7501 37.5 28.7501H2.49997C1.11995 28.7501 0 27.6301 0 26.2501V2.5001C0 1.11885 1.11995 0 2.49997 0Z"
      fill="#E4E7E7"
    />
    <path
      d="M8.04497 4.99899H4.99744V15.0041H8.02865C9.63735 15.0041 10.8012 14.6453 11.8212 13.849C13.0312 12.904 13.75 11.4778 13.75 10.0052C13.75 7.05148 11.4049 4.99899 8.04497 4.99899ZM10.3025 11.8902C9.76129 12.3102 9.06511 12.4901 7.9575 12.4901H7.49877V7.5102H7.9575C9.06497 7.5102 9.73496 7.67897 10.3025 8.11768C10.8951 8.57024 11.2488 9.27012 11.2488 9.99262C11.2488 10.7153 10.8949 11.4415 10.3025 11.8902ZM15.0025 15.0027H17.4851V4.98145H15.0025V15.0027ZM23.1213 8.85526C21.8526 8.44027 21.4801 8.16402 21.4801 7.64894C21.4801 7.0464 22.1451 6.58397 23.055 6.58397C23.6888 6.58397 24.2075 6.81402 24.7614 7.36022L25.8639 6.08151C24.9538 5.37656 238676 5.01654 22.6813 5.01654C20.7688 5.01654 19.3063 6.1953 19.3063 7.7615C19.3063 9.08654 19.9875 9.76147 21.9688 10.394C22.7963 10.6527 23.2176 10.824 23.4288 10.9416C23.8513 11.1866 24.0638 11.5291 24.0638 11.9316C24.0638 12.7103 23.3638 13.284 22.4226 13.284C21.4163 13.284 20.6051 12.8403 20.1176 12.0053L18.7564 13.1729C19.7263 14.4366 20.8963 15.0004 22.5039 15.0004C24.6951 15.0004 26.2364 13.7041 26.2364 11.8491C26.2362 10.3252 25.5238 9.63521 23.1213 8.85526ZM30.0075 9.98645C30.0075 8.42889 31.2637 7.20022 32.8626 7.20022C33.6725 7.20022 34.29 7.4677 34.9976 8.11151L34.9963 5.58399C34.1627 5.17146 33.4739 5.00023 32.6551 5.00023C29.7727 5.00023 27.4626 7.22024 27.4626 9.99769C27.4626 12.8076 29.7163 14.9827 32.6126 14.9827C33.4314 14.9827 34.1339 14.8264 34.9963 14.4265L34.9976 11.8977C34.3151 12.5315 33.7138 12.7865 32.94 12.7865C31.2251 12.7865 30.0075 11.629 30.0075 9.98645Z"
      fill="#324D5B"
    />
    <path
      d="M37.5 28.75C38.88 28.75 40 27.63 40 26.25V16.25L17.4999 28.75H37.5Z"
      fill="#F4B459"
    />
  </SvgIcon>
)

export default DiscoverIcon
