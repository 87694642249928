import { Grid } from '@material-ui/core'
import { createPatient } from 'src/api/prime'
import { createStripeCustomer } from 'src/api/stripe'
import useNotify from 'src/components/banner/useNotify'
import AddPatientForm from 'src/components/forms/AddPatientForm'
import { useGlobalContext } from 'src/context'
import { Patient, PatientInfo } from 'src/lib/types/Patient'
import Loader from 'src/components/Loader'
import Title from 'src/components/base/Title'
import { useEffect, useState } from 'react'
import FormMessage from 'src/components/formBase/FormMessage'
import useTitle from 'src/hooks/useTitle'

const AddPatient = () => {
  const {
    state: { studiosList },
    setProgressBar,
    hideProgressBar,
  } = useGlobalContext()
  const notify = useNotify()
  const [isPatientCreated, setIsPatientCreated] = useState<boolean>(false)
  const { setTitle } = useTitle()

  const handleSubmit = async (patientData: Patient) => {
    try {
      const patientInfo: PatientInfo = { person: { ...patientData } }
      const stripeCustomer = await createStripeCustomer(patientInfo)
      await createPatient({
        ...patientData,
        phone: patientData.primary_phone,
        stripe_customer_id: stripeCustomer.id,
      })
      notify('Patient was created successfully', 'success')
      setIsPatientCreated(true)
    } catch (error) {
      const errorMessage = JSON.stringify(error.data)
      notify(errorMessage, 'error')
      setIsPatientCreated(false)
    }
  }

  const completeForm = () => {
    setIsPatientCreated(false)
    setProgressBar(50)
  }

  useEffect(() => {
    if (isPatientCreated) return

    setProgressBar(50)
    setTitle('Lookup Member')

    return () => {
      hideProgressBar()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isPatientCreated) {
      setProgressBar(100)
      setTitle('Create Patient')
    }

    // eslint-disable-next-line
  }, [isPatientCreated])

  if (!studiosList) {
    return <Loader />
  }

  if (isPatientCreated) {
    return (
      <FormMessage
        title="New member"
        goBack={completeForm}
        message={'The new member has been created'}
        buttonMessage="Go back"
      />
    )
  }

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12} md={10}>
        <Title>New member</Title>
        <AddPatientForm studios={studiosList} onSubmit={handleSubmit} />
      </Grid>
    </Grid>
  )
}

export default AddPatient
