import Stripe from 'stripe'
import getStripe from './getStripe'

const chargeInStripe = async ({ clientSecret, paymentId }) => {
  let customer: Stripe.Customer

  const ecommerceStripe = await getStripe()

  const { error, paymentIntent } = await ecommerceStripe.confirmCardPayment(
    clientSecret,
    {
      payment_method: paymentId,
      setup_future_usage: 'off_session',
    }
  )

  if (error) {
    return { error }
  } else if (paymentIntent) {
    return { customer, paymentIntent }
  }
}

export default chargeInStripe
