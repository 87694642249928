const colors = {
  primary: {
    green: [
      '#E0F0E1', // 0
      '#BCD3BD', // 1
      '#96B9A4', // 2
    ],
    copper: [
      '#BC8452', // 0
      '#6C675C', // 1
      '#F9F3ED', // 2,
      '#F0DECE', // 3
    ],
    grey: [
      '#F9F9F6', // 0
      '#D1CCC2', // 1
    ],
    blue: [
      '#0178D1', // 0
    ],
    blush: [
      '#FFB3AB', // 0
    ],
    brown: [
      '#4C4942', // 0
    ],
  },
  tints: {
    greenAa: [
      '#EAF4EB', // 0
      '#D6EAD7', // 1
    ],
    blue: [
      '#54B3FA', // 0
      '#40A5F0', // 1
      '#2B96E6', // 2
      '#0178D1', // 3
      '#0068B5', // 4
      '#0060A7', // 5
      '#00589A', // 6
    ],
    blush: [
      '#C9553E', // 0
      '#FFB3AB', // 1
      '#FFDED9', // 2
      '#FFECE9', // 3
    ],
    green: [
      '#BCD3BD', // 0
      '#B2CCB7', // 1
      '#A4C2AD', // 2
      '#96B9A4', // 3
      '#85A697', // 4
      '#749389', // 5
      '#698680', // 6
      '#5E7872', // 7
      '#556C67', // 8
    ],
    grey: [
      '#F9F9F6', // 0
      '#EFEDE9', // 1
      '#E5E2DC', // 2
      '#D1CCC2', // 3
      '#ABA69C', // 4
      '#858076', // 5
      '#6C675C', // 6
      '#4D4942', // 7
    ],
    copper: [
      '#DCBFA6', // 0
      '#D4B191', // 1
      '#CCA27C', // 2
      '#BC8452', // 3
      '#9C6C41', // 4
      '#8C6039', // 5
      '#7B5431', // 6
      '#F7F2EC', // 7
      '#F3E6DA', // 8
    ],
  },
  shop: {
    brighter: [
      '#6BBBAE', // 0
      '#80E0A7', // 1
      '#A1D884', // 2
      '#FFB3AB', // 3
    ],
    primary: [
      '#00B2A9', // 0
      '#00B140', // 1
      '#6CC24A', // 2
      '#FF585D', // 3
    ],
    darker: [
      '#007681', // 0
      '#00873E', // 1
      '#A1D884', // 2
      '#F8485E', // 3
    ],
  },
  defaults: {
    white: '#FFF',
    black: '#000',
  },
}

export default colors
