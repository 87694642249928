import {
  Button,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import Title from '../base/Title'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logInForm: {
    marginBottom: '15px',
  },
  errorMessage: {
    color: 'red',
  },
}))

const LoginWithGoogle = ({ errorMessage, signInWithGoogle }) => {
  const classes = useStyles()

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Title>Log In</Title>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            onClick={signInWithGoogle}>
            Sign in with google
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LoginWithGoogle
