import { SvgIcon } from '@material-ui/core'

const VisaIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 13">
    <path
      d="M17.3404 12.7606H14.0765L16.1164 0.22401H19.3806L17.3404 12.7606ZM11.3307 0.22401L8.21898 8.84675L7.85077 6.98994L7.85111 6.99061L6.75285 1.35278C6.75285 1.35278 6.62005 0.22401 5.20456 0.22401H0.0603623L0 0.436284C0 0.436284 1.57311 0.763581 3.41415 1.86921L6.24983 12.7609H9.65057L14.8434 0.22401H11.3307ZM37.003 12.7606H40L37.387 0.223675H34.7632C33.5516 0.223675 33.2565 1.15795 33.2565 1.15795L28.3887 12.7606H31.7911L32.4715 10.8984H36.6207L37.003 12.7606ZM33.4115 8.32596L35.1264 3.63447L36.0912 8.32596H33.4115ZM28.6439 3.23876L29.1097 0.546612C29.1097 0.546612 27.6724 0 26.174 0C24.5543 0 20.7079 0.707914 20.7079 4.15023C20.7079 7.389 25.2223 7.42924 25.2223 9.13045C25.2223 10.8317 21.173 10.5268 19.8367 9.45406L19.3514 12.2689C19.3514 12.2689 20.8089 12.9769 23.0355 12.9769C25.2629 12.9769 28.6231 11.8236 28.6231 8.68477C28.6231 5.42522 24.0681 5.12173 24.0681 3.70456C24.0684 2.28706 27.2472 2.46915 28.6439 3.23876Z"
      fill="#2566AF"
    />
  </SvgIcon>
)

export default VisaIcon
