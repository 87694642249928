import NumberFormat from 'react-number-format'
import { Controller } from 'react-hook-form'
import { TextField } from '@material-ui/core'

const PhoneInput = ({ control, name, errors, id = undefined, ...other }) => {
  return (
    <Controller
      render={({ field }) => (
        <NumberFormat
          {...field}
          {...other}
          type="tel"
          customInput={TextField}
          format="(###) ###-####"
          inputRef={field.ref}
          id={id ?? name}
          error={errors && !!errors[name]}
          helperText={errors && errors[name]?.message}
        />
      )}
      name={name}
      control={control}
    />
  )
}

export default PhoneInput
