import { useEffect } from 'react'
import { useGlobalContext } from 'src/context'

const useTitle = () => {
  const { setTitle, hideTitle } = useGlobalContext()

  useEffect(() => {
    return () => {
      hideTitle()
    }
    //eslint-disable-next-line
  }, [])

  return { setTitle }
}

export default useTitle
