import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid, InputAdornment, makeStyles } from '@material-ui/core'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import TextInput from '../formBase/TextInput'
import {
  CardCvcElement,
  CardElementComponent,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElementChangeEvent,
} from '@stripe/stripe-js'
import { PatientInfo } from '../../lib/types/Patient'
import StripeInput from '../formBase/StripeInput'
import CreditCardLogo from '../base/CreditCardLogo'
import { getStripe, setupIntents, storeCardInStripe } from 'src/api/stripe'
import { getStripeErrorField } from 'src/utils'
import { maxCharacterLengthValidationErrorMessage } from 'src/utils'

interface Props {
  onSubmit: (data: CreditCard) => void
  patientInfo: PatientInfo
}

export interface CreditCard {
  firstName: string
  lastName: string
}

interface FormStateValues {
  cardNumber: StripeCardNumberElementChangeEvent
  cardExpiry: StripeCardExpiryElementChangeEvent
  cardCvc: StripeCardCvcElementChangeEvent
}

const schema: yup.SchemaOf<CreditCard> = yup.object().shape({
  firstName: yup
    .string()
    .max(100, maxCharacterLengthValidationErrorMessage(100))
    .required(),
  lastName: yup
    .string()
    .max(100, maxCharacterLengthValidationErrorMessage(100))
    .required(),
})

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  container: {
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(1),
  },
}))

const stripePromise = getStripe()

const AddCardForm: FC<Props> = ({ onSubmit, patientInfo }) => {
  const classes = useStyles()
  const stripe = useStripe()
  const elements = useElements()
  const [errorState, setErrorState] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [billingDetails, setBillingDetails] = useState({
    email: '',
    phone: '',
    name: '',
  })

  const [formState, setFormState] = useState<FormStateValues>(null)

  const handleForm = async (data: CreditCard) => {
    if (!stripe || !elements) return
    if (data.firstName === '' || data.lastName === '') return

    const {
      person: { email, primary_phone: phone, stripe_customer_id: customerId },
    } = patientInfo
    const billing_details = {
      email,
      name: `${data.firstName} ${data.lastName}`,
      phone,
    }
    const intentsRes = await setupIntents()

    const { error, setupIntent } = await stripe.confirmCardSetup(
      intentsRes.client_secret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details,
        },
      }
    )

    if (error) {
      setErrorState(error)
      return
    } else if (setupIntent) {
      if (setupIntent.status === 'succeeded') {
        const { payment_method: paymentMethodId } = setupIntent

        const payment = await storeCardInStripe({
          customerId,
          paymentMethodId,
          ...billing_details,
        })

        setPaymentMethod(payment)
      }

      setBillingDetails({ ...billing_details })
    }
  }

  useEffect(() => {
    if (billingDetails.name === '') return
    if (!paymentMethod) return

    onSubmit({ ...billingDetails, ...paymentMethod })
  }, [paymentMethod, onSubmit, billingDetails])

  useEffect(() => {
    if (!stripe || !elements) return

    if (errorState) {
      const field = getStripeErrorField(errorState.code)
      elements.getElement(field as CardElementComponent).focus()
      setErrorState(null)
      return
    }
  }, [errorState, setErrorState, stripe, elements])

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CreditCard>({
    defaultValues: {},
    resolver: yupResolver(schema),
  })

  const handleElementChange = (event: StripeElementChangeEvent) => {
    setFormState((prevFormState) => {
      return { ...prevFormState, [event.elementType]: event }
    })
  }

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(handleForm)}>
        <TextInput
          id="firstName"
          name="firstName"
          control={control}
          label="First name"
          fullWidth
          className={classes.input}
          disabled={isSubmitting}
        />
        <TextInput
          id="lastName"
          name="lastName"
          control={control}
          label="Last name"
          fullWidth
          className={classes.input}
          disabled={isSubmitting}
        />
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <StripeInput
              label="Card number"
              stripeElement={CardNumberElement}
              onChange={handleElementChange}
              error={formState?.cardNumber?.error}
              disabled={isSubmitting}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CreditCardLogo brand={formState?.cardNumber?.brand} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={7}>
            <StripeInput
              label="Expiration date"
              stripeElement={CardExpiryElement}
              onChange={handleElementChange}
              error={formState?.cardExpiry?.error}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={5}>
            <StripeInput
              label="CVC"
              stripeElement={CardCvcElement}
              onChange={handleElementChange}
              error={formState?.cardCvc?.error}
              disabled={isSubmitting}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            fullWidth>
            Add card
          </Button>
        </Grid>
      </form>
    </div>
  )
}

const AddCard = ({ onSubmit, patientInfo }) => {
  return (
    <Elements stripe={stripePromise}>
      <AddCardForm onSubmit={onSubmit} patientInfo={patientInfo} />
    </Elements>
  )
}

export default AddCard
