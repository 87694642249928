const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:91e5b0ea-4b3c-4051-ae9d-2d3d668fd90c',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_IxEAGv6Cy',
  aws_user_pools_web_client_id: '2foic2t0dt0jv46g2f6h7dogu',
  oauth: {
    domain:
      'emergencrmb13836b8-b13836b8-production.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn:
      'http://localhost:3000/,https://emergen-crm.tend.vercel.app/,https://emergen-crm.hellotend.com/',
    redirectSignOut:
      'http://localhost:3000/,https://emergen-crm.tend.vercel.app/,https://emergen-crm.hellotend.com/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: ['GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_cognito_login_mechanisms: ['EMAIL'],
  aws_cognito_login_mechanism: ['EMAIL'],
}

export default awsmobile
