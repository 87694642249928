export type ItemType = Record<string, string> | string

const replaceSpaces = (str: string) => str.replace(' ', '-')

export const selectInputMapper = (
  itemList: any[],
  idKey?: string,
  labelKey?: string
) =>
  itemList.map((item: ItemType) =>
    typeof item === 'object'
      ? { id: item[idKey], label: item[labelKey] }
      : typeof item === 'string'
      ? { id: replaceSpaces(item), label: replaceSpaces(item) }
      : null
  )
