import {
  createStyles,
  Grid,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getOnBoardingClient } from 'src/api/contentful'
import Title from 'src/components/base/Title'
import RightArrow from 'src/components/icons/RightArrow'
import Loader from 'src/components/Loader'
import useTitle from 'src/hooks/useTitle'
import { PatientInfo } from 'src/lib/types/Patient'
import { colors } from 'src/theme'
import { getServiceCode } from 'src/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.primary.grey[0],
    },
    title: {
      paddingBottom: theme.spacing(3),
      borderBottom: `3px solid ${colors.tints.grey[7]}`,
      margin: '0',
    },
    listItem: {
      padding: theme.spacing(1.75, 0),
      borderBottom: `1px solid ${colors.tints.green[7]}`,
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover': {
        color: `${colors.tints.blue[4]}`,
        borderColor: `${colors.tints.blue[4]}`,
      },
    },
    listItemTextPrimary: {
      fontSize: 20,
      fontFamily: 'Nantes',
      color: colors.tints.green[7],
      fontWeight: 'normal',
      [theme.breakpoints.up('md')]: {
        fontSize: 32,
      },
    },
    listItemTextSecondary: {
      fontSize: 18,
      color: colors.tints.green[7],
      opacity: 0.7,
      paddingTop: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
        paddingTop: theme.spacing(1.5),
      },
    },
  })
)

interface Service {
  code: string
  name: string
  text: string
}

const ServiceSelector = () => {
  const [services, setServices] = useState(null)
  const classes = useStyles()
  const { setTitle } = useTitle()
  const location = useLocation()
  const history = useHistory()

  let patientInfo: PatientInfo

  if (!location.state) {
    history.push('/patients')
  }

  patientInfo = location.state['patientInfo'] as PatientInfo
  const patientName = `${patientInfo.person.first_name} ${patientInfo.person.last_name}`

  useEffect(() => {
    if (services) return
    const getServices = async () => {
      const {
        fields: { services },
      } = await getOnBoardingClient()
      setServices(services)
    }

    getServices()
  }, [services])

  useEffect(() => {
    setTitle(patientName)
    // eslint-disable-next-line
  }, [])

  if (!services) {
    return <Loader />
  }

  const redirectToBooking = (service: Service) => {
    const serviceCode = service.code
      ? service.code
      : getServiceCode(service.name)

    history.push(`/patients/${patientInfo.id}/booking`, {
      patientInfo,
      serviceCode,
    })
  }

  return (
    <Grid
      container
      spacing={3}
      className={classes.root}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={12} md={10}>
        <div>
          <Title aditionalClass={classes.title}>Service Type</Title>
        </div>
        <List>
          {services &&
            services.map((service: Service) => (
              <ListItem
                button
                component="a"
                key={service.name.replace(' ', '-')}
                onClick={() => redirectToBooking(service)}
                className={classes.listItem}
              >
                <div>
                  <Typography
                    variant="h4"
                    component="span"
                    className={classes.listItemTextPrimary}
                  >
                    {service.name}
                  </Typography>
                  <Typography className={classes.listItemTextSecondary}>
                    {service.text}
                  </Typography>
                </div>
                <RightArrow />
              </ListItem>
            ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default ServiceSelector
