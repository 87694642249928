import StripeField from './StripeField'
import { makeStyles, TextField, Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
}))

const StripeInput = (props) => {
  const { InputLabelProps, stripeElement, InputProps, error, ...other } = props
  const classes = useStyles()

  return (
    <TextField
      className={classes.input}
      error={!!error}
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          component: stripeElement,
        },
        inputComponent: StripeField,
      }}
      helperText={error && error.message}
      {...other}
    />
  )
}

export default StripeInput
