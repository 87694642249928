import React from 'react'
import { Controller, Control } from 'react-hook-form'
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'

interface RadioGroupOption {
  value: string | number | boolean
  label: string
}

interface RadioGroupProps {
  name: string
  control: Control<any>
  options: RadioGroupOption[]
  label: string
  defaultValue?: any
  format?: (value: string | number | boolean) => string
  parse?: (value: string) => string | number | boolean
  className?: string
  [key: string]: any
  size?: 'small' | 'medium'
}

const RadioGroup = ({
  name,
  control,
  options,
  label,
  defaultValue = '',
  format = (v) => v.toString(),
  parse = (v) => v,
  className = '',
  row = false,
  size = 'small',
}: RadioGroupProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={format(defaultValue)}
      render={({ field }) => (
        <FormControl component="fieldset" fullWidth className={className}>
          <FormLabel component="legend">{label}</FormLabel>
          <MuiRadioGroup
            row={row}
            aria-label={field.name}
            {...field}
            onChange={(e) => {
              field.onChange(parse(e.target.value))
            }}>
            {options.map(({ value, label }) => (
              <FormControlLabel
                key={format(value)}
                control={<Radio size={size} />}
                value={value}
                label={label}
              />
            ))}
          </MuiRadioGroup>
        </FormControl>
      )}
    />
  )
}

export default RadioGroup
