import { Color as AlertSeverity } from '@material-ui/lab'
import React, { createContext, FunctionComponent, useState } from 'react'

interface NotificationBannerContextProps {
  open: boolean
  autoHideDuration: number | null
  showBanner: (message: string, severity?: AlertSeverity) => void
  hideBanner: (event?: React.SyntheticEvent, reason?: string) => void
  severity: AlertSeverity
  message: string
}

const defaults: NotificationBannerContextProps = {
  open: false,
  autoHideDuration: null,
  showBanner: null,
  hideBanner: null,
  severity: 'info',
  message: '',
}

export const NotificationBannerContext =
  createContext<NotificationBannerContextProps>(defaults)

interface NotificationBannerContextProviderProps {
  autoHideDuration?: number | null
}

const NotificationBannerContextProvider: FunctionComponent<NotificationBannerContextProviderProps> =
  ({ children, autoHideDuration = 6000 }) => {
    const [open, setOpen] = useState(false)
    const [severity, setSeverity] = useState<AlertSeverity>('info')
    const [message, setMessage] = useState('')

    const hideBanner = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return
      }

      setOpen(false)
    }

    const showBanner = (
      message: string,
      severity: AlertSeverity = 'success'
    ) => {
      if (open) {
        hideBanner()
      }

      // We need to reopen banner so we need to close it first then open again on next render
      setTimeout(() => {
        setMessage(message)
        setSeverity(severity)
        setOpen(true)
      }, 0)
    }

    return (
      <NotificationBannerContext.Provider
        value={{
          open,
          autoHideDuration,
          severity,
          message,
          showBanner,
          hideBanner,
        }}>
        {children}
      </NotificationBannerContext.Provider>
    )
  }

export default NotificationBannerContextProvider
