import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Subtitle } from 'src/components/base'
import Title from 'src/components/base/Title'
import useTitle from 'src/hooks/useTitle'
import { PatientInfo } from 'src/lib/types/Patient'
import { PaymentCharge } from 'src/lib/types/stripe'
import { formatDateUnix, toUSDString } from 'src/utils'

const useStyles = makeStyles(() =>
  createStyles({
    longString: {
      whiteSpace: 'nowrap',
    },
  })
)

const ChargeList = () => {
  const location = useLocation()
  const history = useHistory()
  const { setTitle } = useTitle()
  const classes = useStyles()

  let patientInfo: PatientInfo

  if (!location.state) {
    history.push('/patients')
  }

  patientInfo = location.state['patientInfo'] as PatientInfo

  const patientName = `${patientInfo.person.first_name} ${patientInfo.person.last_name}`
  const chargeList = location.state['patientCharges']
  const hasCharges = chargeList.length !== 0

  useEffect(() => {
    setTitle(patientName)
    // eslint-disable-next-line
  }, [patientName])

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12} md={10}>
        <div>
          <Title>Charge List</Title>
          {hasCharges ? (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Account Name</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Date</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chargeList.map((charge: PaymentCharge) => (
                    <TableRow key={charge.id}>
                      <TableCell>
                        {charge.billing_details.name} {charge.receipt_email}
                      </TableCell>
                      <TableCell className={classes.longString}>
                        {toUSDString(charge.amount / 100)} - {charge.currency}
                      </TableCell>
                      <TableCell className={classes.longString}>
                        <p>{formatDateUnix(charge.created)} </p>
                      </TableCell>
                      <TableCell>{charge.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Subtitle>This patient doesn't have any charge</Subtitle>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default ChargeList
