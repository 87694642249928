import { SvgIcon } from '@material-ui/core'

const MastercardIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 36 22">
    <path
      d="M35.1815 17.7957V17.3691H35.0705L34.9421 17.6619L34.8144 17.3691H34.703V17.7957H34.7819V17.4744L34.9019 17.7516H34.9836L35.1037 17.4737V17.7957H35.1818H35.1815ZM34.4773 17.7957V17.4422H34.6194V17.3702H34.2563V17.4422H34.3984V17.7957H34.4766H34.4773Z"
      fill="#F79410"
    />
    <path
      d="M22.5654 19.6246H12.9597V2.34863H22.5655L22.5654 19.6246Z"
      fill="#FF5F00"
    />
    <path
      d="M13.5686 10.9862C13.5686 7.48174 15.2082 4.35997 17.7614 2.34818C15.8288 0.823105 13.4384 -0.00460393 10.9773 -0.000964612C4.91455 -0.000964612 0 4.91807 0 10.9862C0 17.0543 4.91455 21.9733 10.9773 21.9733C13.4385 21.977 15.8289 21.1493 17.7616 19.6242C15.2086 17.6127 13.5686 14.4908 13.5686 10.9862Z"
      fill="#EB001B"
    />
    <path
      d="M35.5248 10.9871C35.5248 17.0552 30.6103 21.9743 24.5475 21.9743C22.0861 21.9779 19.6954 21.1502 17.7623 19.6251C20.312 17.6133 21.9558 14.4917 21.9558 10.9871C21.9558 7.48254 20.3162 4.36095 17.7623 2.34915C19.6953 0.824127 22.0859 -0.00356358 24.5474 1.15332e-05C30.6101 1.15332e-05 35.5246 4.91904 35.5246 10.9871"
      fill="#F79E1B"
    />
  </SvgIcon>
)

export default MastercardIcon
