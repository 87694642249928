import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { colors } from 'src/theme'
import { phoneFormater } from 'src/utils'
import { PatientInfo } from '../../lib/types/Patient'
import { Eyebrow, Subtitle } from '../base'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      backgroundColor: colors.primary.grey[0],
      padding: '10px',
      marginBottom: '10px',
    },
  })
)

const Patient = ({ patientData }: { patientData: PatientInfo }) => {
  const classes = useStyles()
  const {
    person: {
      first_name,
      last_name,
      primary_phone,
      addresses,
      email,
      preferred_name,
    },
  } = patientData

  return (
    <Accordion className={classes.wrapper} elevation={1}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>Patient Detail</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Eyebrow>Chart Number</Eyebrow>
          <Subtitle>{patientData.chart_number}</Subtitle>
          <Eyebrow>Patient Name</Eyebrow>
          <Subtitle>
            {first_name} {last_name}
          </Subtitle>
          {preferred_name && (
            <>
              <Eyebrow>Preferred Name</Eyebrow>
              <Subtitle>{preferred_name}</Subtitle>
            </>
          )}
          {email && (
            <>
              <Eyebrow>Email</Eyebrow>
              <Subtitle>{email}</Subtitle>
            </>
          )}
          {primary_phone && (
            <>
              <Eyebrow>Phone</Eyebrow>
              <Subtitle>{phoneFormater(primary_phone)}</Subtitle>
            </>
          )}
          {addresses && addresses.length > 0 && (
            <>
              <Eyebrow>Address</Eyebrow>
              <Subtitle> {addresses[0].address1} </Subtitle>
              <Eyebrow>State</Eyebrow>
              <Subtitle> {addresses[0].state} </Subtitle>
              <Eyebrow>City</Eyebrow>
              <Subtitle> {addresses[0].city} </Subtitle>
            </>
          )}
          <Eyebrow>Patient type</Eyebrow>
          <Subtitle>{patientData.patient_type}</Subtitle>
          {patientData.has_insurance && patientData.active_insurance && (
            <div>
              <Eyebrow>Insurance Provider</Eyebrow>
              <Subtitle>{patientData.active_insurance.payer}</Subtitle>
              <Eyebrow>Insurance policy holder</Eyebrow>
              <Subtitle>
                {patientData.active_insurance.policyholder_first_name}{' '}
                {patientData.active_insurance.policyholder_last_name}
              </Subtitle>
              <Eyebrow>Policy number</Eyebrow>
              <Subtitle>{patientData.person.id}</Subtitle>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default Patient
