import axios from 'axios'
import jwtDecode from 'jwt-decode'

interface ApiAuthResponseData {
  idToken: string
  accessToken: string
  refreshToken: string
  createdAt: string
  lastLogin: string
}

interface ApiAuthRefreshResponseData {
  AuthenticationResult: {
    AccessToken: string
    IdToken: string
    ExpiresIn: number
    TokenType: string
  }
}

interface ApiAuthIdTokenPayload {
  aud: string
  auth_time: number
  'congnito:username': string
  email: string
  email_verified: boolean
  event_id: string
  exp: number
  iat: number
  iss: string
  issued_by: string
  roles: string
  sub: string
  token_use: string
}

interface ApiAuthManager {
  getIdToken: () => string
  logout: () => void
}

const REFRESH_GAP_IN_SECONDS = 10

class AuthManager implements ApiAuthManager {
  private idToken: string | null = null
  private refreshToken: string | null = null
  private refreshTimerId

  init = async () => {
    try {
      this.logout()

      const { data } = await axios.get<ApiAuthResponseData>(
        `${process.env.REACT_APP_TEND_SERVICE_AUTH_URL}/service/auth`
      )

      this.updateTokens(data.idToken, data.refreshToken)
    } catch (e) {
      console.error(e)
    }
  }

  getIdToken = () => this.idToken

  logout = () => {
    this.idToken = null
    this.refreshToken = null
    if (this.refreshTimerId) {
      clearTimeout(this.refreshTimerId)
    }
  }

  private updateTokens = (idToken: string, refreshToken?: string) => {
    try {
      const payload = jwtDecode<ApiAuthIdTokenPayload>(idToken)

      this.idToken = idToken
      if (refreshToken) {
        this.refreshToken = refreshToken
      }

      const expiresInSeconds = payload.exp - payload.iat

      this.refreshTimerId = setTimeout(
        this.refreshTokens,
        (expiresInSeconds - REFRESH_GAP_IN_SECONDS) * 1000
      )
    } catch (e) {
      console.error(e)
      this.logout()
    }
  }

  private refreshTokens = async () => {
    try {
      const response = await axios.post<ApiAuthRefreshResponseData>(
        `${process.env.REACT_APP_TEND_IDENTITY_URL}/service/refresh-token`,
        { refreshToken: this.refreshToken }
      )

      const idToken = response.data.AuthenticationResult.IdToken

      this.updateTokens(idToken)
    } catch (e) {
      console.error(e)
      this.logout()
    }
  }
}

let apiAuthManagerPromise: Promise<ApiAuthManager>

const createApiAuthManager = async (): Promise<ApiAuthManager> => {
  const manager = new AuthManager()

  await manager.init()

  return manager
}

export const getApiAuthManager = async (): Promise<ApiAuthManager> => {
  if (!apiAuthManagerPromise) {
    apiAuthManagerPromise = createApiAuthManager()
  }

  return apiAuthManagerPromise
}

export default getApiAuthManager
