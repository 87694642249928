import { alpha, useTheme } from '@material-ui/core'
import { useImperativeHandle, useState } from 'react'

const StripeField = (props) => {
  const { component: Component, inputRef, options, ...other } = props
  const theme = useTheme()
  const [mountNode, setMountNode] = useState(null)

  useImperativeHandle(
    inputRef,
    () => ({
      focus: () => mountNode.focus(),
    }),
    [mountNode]
  )

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: '20px',
            fontFamily: theme.typography.fontFamily,
            '::placeholder': {
              color: alpha(theme.palette.text.primary, 0.42),
            },
          },
          invalid: {
            color: theme.palette.text.disabled,
          },
        },
      }}
      {...other}
    />
  )
}

export default StripeField
