import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core'
import {
  ChargeDetails,
  PatientInfo,
  PatientOrthoPhoto,
} from '../lib/types/Patient'
import Patient from '../components/patient'
import { Link } from 'react-router-dom'
import Title from 'src/components/base/Title'
import { colors } from 'src/theme'
import { ArrowForwardIos as ArrowForwardIcon } from '@material-ui/icons'
import { Eyebrow } from 'src/components/base'
import { upperFirst } from 'lodash'
import useAuthenticator from 'src/hooks/useAuthenticator'

const isCrmEnabled = process.env.REACT_APP_ENABLE_EMERGENCY_CRM === 'true'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.primary.grey[0],
    },
    linkContainer: {
      display: 'flex',
      justifyContent: 'space-around',
    },
    link: {
      textDecoration: 'none',
    },
    title: {
      paddingBottom: theme.spacing(3),
      borderBottom: `3px solid ${colors.tints.grey[7]}`,
    },
    itemList: {
      backgroundColor: colors.primary.grey[0],
      borderTop: `1px solid ${colors.tints.grey[7]}`,
      borderRadius: '0',
      padding: theme.spacing(2, 0, 2),
      margin: '0',
      '& a': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        textDecoration: 'none',
        color: `${colors.tints.grey[7]}`,
      },
    },
  })
)

interface PatientPaymentInfo {
  billing_details: {
    email: string
    name: string
    phone: string
  }
  card: {
    brand: string
    exp_mounth: string
    funding: string
    last4: string
  }
  created: number
  custoner: string
  id: string
  livemode: boolean
  metadata: Record<string, unknown>
  object: string
  type: string
}

interface Props {
  patientInfo: PatientInfo
  patientPaymentInfo: PatientPaymentInfo[] | null
  patientCharges: ChargeDetails[] | null
  patientOrthoPhotos: PatientOrthoPhoto[] | []
}

const PatientDetailContainer = ({
  patientInfo,
  patientPaymentInfo,
  patientCharges,
  patientOrthoPhotos,
}: Props) => {
  const {
    state: { hasDentrixAccount },
  } = useAuthenticator()

  const classes = useStyles()
  const {
    person: { first_name, last_name },
    has_insurance,
  } = patientInfo
  const patientName = `${first_name} ${last_name}`
  const cardAmount =
    patientPaymentInfo && patientPaymentInfo?.length !== 0
      ? patientPaymentInfo.length
      : 'No'
  const hasCharges = patientCharges?.length !== 0

  return (
    <Grid
      container
      spacing={3}
      className={classes.root}
      justifyContent="center"
      alignItems="center">
      <Grid item xs={12} sm={12} md={10}>
        <div>
          <Title aditionalClass={classes.title}>
            {patientName} - {patientInfo.chart_number}
          </Title>
        </div>
        <Patient patientData={patientInfo} />
        {isCrmEnabled && (
          <Paper elevation={0} className={classes.itemList}>
            <Link
              to={{
                pathname: `/patients/${patientInfo.chart_number}/submit-insurance`,
                state: { patientInfo, paymentInfo: patientPaymentInfo },
              }}
              className={classes.link}>
              <div>
                <Typography variant="h4">
                  Submit Insurance Information
                </Typography>
                <Eyebrow>
                  {has_insurance
                    ? 'Member has insurance on file'
                    : 'Member does not have insurance on file'}
                </Eyebrow>
              </div>
              <ArrowForwardIcon />
            </Link>
          </Paper>
        )}

        {isCrmEnabled && patientPaymentInfo && (
          <Paper elevation={0} className={classes.itemList}>
            <Link
              to={{
                pathname: `/patients/${patientInfo.chart_number}/payment-list`,
                state: { patientInfo, paymentInfo: patientPaymentInfo },
              }}
              className={classes.link}>
              <div>
                <Typography variant="h4">Payments</Typography>
                <Eyebrow> {cardAmount} cards on file</Eyebrow>
              </div>
              <ArrowForwardIcon />
            </Link>
          </Paper>
        )}
        {isCrmEnabled && patientCharges && (
          <Paper elevation={0} className={classes.itemList}>
            <Link
              to={{
                pathname: `/patients/${patientInfo.chart_number}/charge-list`,
                state: { patientInfo, patientCharges },
              }}
              className={classes.link}>
              <div>
                <Typography variant="h4">Charges</Typography>
                <Eyebrow>
                  {hasCharges ? patientCharges.length : 'No'} charges
                </Eyebrow>
              </div>
              <ArrowForwardIcon />
            </Link>
          </Paper>
        )}
        {hasDentrixAccount && (
          <Paper elevation={0} className={classes.itemList}>
            <Link
              to={{
                pathname: `/patients/${patientInfo.chart_number}/images`,
                state: { patientInfo, patientOrthoPhotos },
              }}
              className={classes.link}>
              <div>
                <Typography variant="h4">Images</Typography>
                <Typography>
                  {patientOrthoPhotos && patientOrthoPhotos.length
                    ? `${patientOrthoPhotos.length} Images`
                    : 'No images available'}
                </Typography>
              </div>
              <ArrowForwardIcon />
            </Link>
          </Paper>
        )}
      </Grid>
    </Grid>
  )
}

export default PatientDetailContainer
