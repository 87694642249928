import { format } from 'date-fns'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import queryString from 'query-string'
import { Studio } from 'src/lib/types/Studio'
import saveAs from 'file-saver'

interface QueryParamsObject {
  [property: string]: string
}

export const getUrlWithQueryString = (
  url: string,
  params: QueryParamsObject
) => {
  const generatedUrl = queryString.stringifyUrl({
    url,
    query: { ...params },
  })

  return generatedUrl
}

export const sortStudiosByName = (studios: Studio[]) =>
  studios.sort((a, b) => a.name.localeCompare(b.name))

export const hasElements = (itemsList: unknown[]) =>
  itemsList !== null && itemsList.length > 0

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const unixTimeToUTC = (unixTime: number) => {
  const dateObj = new Date(unixTime * 1000)
  return dateObj.toUTCString()
}

export const serviceCode = {
  Procedures: 'IMPCON',
  Cosmetic: 'COSCON',
  'Breezy-Braces-Consult': 'BRZYCONS',
  Emergency: 'EMGNCY',
  'Dental-Exam': 'CLNCHK',
}

export const getServiceCode = (serviceName: string) =>
  serviceCode[serviceName.replace(/ /g, '-')]

export const toUSDString = (amount: number | string, hideZeroCents = true) => {
  const numberAmount = Number(amount)

  if (Number.isNaN(numberAmount)) {
    return 'N/A'
  }

  let result = numberAmount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  if (hideZeroCents) {
    result = result.replace('.00', '')
  }

  return result
}

export const formatDate = (patientDateOfBirth: string) =>
  format(new Date(patientDateOfBirth), 'yyyy-MM-dd')

export const formatDateAndTime = (date: string) =>
  format(new Date(date), 'yyyy-MM-dd h:m')

export const formatDateUnix = (unixDate: number) =>
  formatDate(String(unixTimeToUTC(unixDate)))

export const phoneFormater = (phoneNumber: string) =>
  phoneNumber
    .replace(/\+1/g, '')
    .replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3')

export const hours = [
  { day: 'Monday - Friday', hours: '7AM - 8PM' },
  {
    day: 'Saturday',
    hours: '8AM - 4PM',
  },
  { day: 'Sunday', hours: 'Closed' },
]

const stripeErrorCode = {
  incomplete_expiry: 'cardExpiry',
  incomplete_number: 'cardNumber',
  incomplete_cvc: 'cardCvc',
}

export const getStripeErrorField = (code: string) => stripeErrorCode[code]

const fetchImageFile = (url: string) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    JSZipUtils.getBinaryContent(url, (err, data) => {
      if (err) {
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}

const getFileExtension = (url: string) => {
  var regex = /(?:\.([^.]+))?$/
  const parseURL = url.split('?')[0]
  return regex.exec(parseURL)[1]
}

export const downloadZip = (fileList: string[]) => {
  var zip = new JSZip()
  var zipFilename = 'Pictures.zip'

  fileList.forEach((url, index) => {
    const fileExtension = getFileExtension(url)
    zip.file(`${index} - image.${fileExtension}`, fetchImageFile(url), {
      binary: true,
    })
  })

  zip.generateAsync({ type: 'blob' }).then((blob) => {
    saveAs(blob, zipFilename)
  })
}

export const maxCharacterLengthValidationErrorMessage = (maxCharacterLength: number) =>
  `Ensure this field has no more than ${maxCharacterLength} characters`
