import React, { useContext } from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { NotificationBannerContext } from './NotificationBannerContext'

const NotificationBanner = () => {
  const { open, autoHideDuration, hideBanner, severity, message } = useContext(
    NotificationBannerContext
  )

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={autoHideDuration}
      onClose={hideBanner}
      data-testid="notification-banner">
      <Alert onClose={hideBanner} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default NotificationBanner
