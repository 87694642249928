import { makeStyles, Typography } from '@material-ui/core'
import colors from '../../theme/colors'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '18px',
    lineHeight: '150%',
    color: colors.tints.grey[4],
  },
  white: {
    color: colors.primary.grey[0],
  },
}))

interface Props {
  children: React.ReactNode
  isWhite?: boolean
  className?: string
}

const Eyebrow = ({ children, isWhite = false, className }: Props) => {
  const classes = useStyles()

  return (
    <Typography
      display="block"
      variant="body1"
      className={clsx(classes.root, className, { [classes.white]: isWhite })}>
      {children}
    </Typography>
  )
}

export default Eyebrow
