import { createStyles, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    subtitle: {
      marginBottom: theme.spacing(2),
    },
  })
)

const Subtitle = ({ children }) => {
  const classes = useStyles()

  return (
    <Typography variant="h5" display="block" className={classes.subtitle}>
      {children}
    </Typography>
  )
}

export default Subtitle
