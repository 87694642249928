import { useCallback, useEffect, useState } from 'react'
import {
  createStripeCustomer,
  getChargesByCustomerId,
  getStripeCustomer,
} from 'src/api/stripe'
import { PatientInfo } from 'src/lib/types/Patient'

const useStripeApi = (stripeCustomer?: string) => {
  let currentPatientStripeId = stripeCustomer ?? null
  const [patientStripeInfo, setPatientStripeInfo] = useState(null)
  const [patientCharges, setPatientCharges] = useState(null)

  const getStripeUserById = useCallback(async () => {
    const res = await getStripeCustomer(currentPatientStripeId)
    setPatientStripeInfo(res.data)
  }, [currentPatientStripeId])

  const createStripe = async (patientInfo: PatientInfo) => {
    const stripeInfo = await createStripeCustomer(patientInfo)
    setPatientStripeInfo(stripeInfo)
    currentPatientStripeId = stripeInfo.id
  }

  const getStripeCharges = useCallback(async () => {
    if (!currentPatientStripeId) return

    const customerCharges = await getChargesByCustomerId(
      currentPatientStripeId,
      10
    )
    setPatientCharges(customerCharges.data)
  }, [currentPatientStripeId])

  useEffect(() => {
    if (!currentPatientStripeId) return

    getStripeUserById()
  }, [currentPatientStripeId, getStripeUserById])

  return {
    createStripe,
    getStripeCharges,
    patientCharges,
    patientStripeInfo,
  }
}

export default useStripeApi
