const RightArrow = () => (
  <svg viewBox="0 0 40 40" height="40" width="40" fill="none">
    <path
      transform="rotate(180, 20, 20)"
      d="M24 12.0001L16 20.0001L24 28.0001"
      stroke="#6C675C"
      strokeWidth="1.5"></path>
  </svg>
)

export default RightArrow
