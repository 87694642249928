import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { setInsuranceProvider } from 'src/api/prime'
import useNotify from 'src/components/banner/useNotify'
import Title from 'src/components/base/Title'
import FormMessage from 'src/components/formBase/FormMessage'
import InsuranceForm from 'src/components/forms/InsuranceForm'
import Loader from 'src/components/Loader'
import { useGlobalContext } from 'src/context'
import useTitle from 'src/hooks/useTitle'
import { PatientInfo } from 'src/lib/types/Patient'
import { formatDate } from 'src/utils'

const AddInsurance = () => {
  const location = useLocation()
  const history = useHistory()
  const [hasInsurance, setHasInsurance] = useState<boolean>(false)
  const { setTitle } = useTitle()
  const notify = useNotify()

  let patientInfo: PatientInfo
  let successMessage: string

  if (!location.state) {
    history.push('/patients')
  }

  patientInfo = location.state['patientInfo'] as PatientInfo
  const {
    state: { insuranceProvidersList },
    setProgressBar,
    hideProgressBar,
  } = useGlobalContext()
  const patientName = `${patientInfo.person.first_name} ${patientInfo.person.last_name}`

  const submitInsurance = async (data) => {
    const { person, id } = patientInfo

    const currentProvider = insuranceProvidersList.find(
      (provider) => provider.id === data.payer
    )

    const formData = {
      ...data,
      provider_name: currentProvider.name,
      id: currentProvider.id,
      payer: currentProvider.name,
      patient_dob: formatDate(
        patientInfo.person.date_of_birth ||
          data.policyholder_dob ||
          data.member_dob
      ),
      policyholder_first_name:
        data.policyholder_first_name || data.member_first_name,
      policyholder_last_name:
        data.policyholder_last_name || data.member_last_name,
      policyholder_dob: formatDate(data.policyholder_dob || data.member_dob),
      patient: id,
      patient_legal_first_name: person.first_name,
      patient_legal_last_name: person.last_name,
      policyholder_member_id: data.policyholder_member_id || data.member_id,
      policyholder_zip: null,
      is_active: true,
    }

    try {
      await setInsuranceProvider(formData)
      successMessage = `${formData.payer}
      Policyholder: ${formData.policyholder_first_name} ${formData.policyholder_last_name}`
      setHasInsurance(true)
    } catch (error) {
      notify(JSON.stringify(error.data), 'error')
    }
  }

  useEffect(() => {
    setTitle(patientName)
    // eslint-disable-next-line
  }, [patientName])

  useEffect(() => {
    if (hasInsurance) {
      notify('Insurance has been added', 'success')
      history.push(`/patients/${patientInfo.chart_number}/`, { patientInfo })
    }
    // eslint-disable-next-line
  }, [hasInsurance])

  useEffect(() => {
    setProgressBar(50)

    return () => {
      hideProgressBar()
    }
    // eslint-disable-next-line
  }, [])

  const completeForm = () => {
    setHasInsurance(false)
    setProgressBar(50)
  }

  if (hasInsurance) {
    return (
      <FormMessage
        title="Insurance added"
        goBack={completeForm}
        message={successMessage}
        buttonMessage="Go back"
      />
    )
  }

  if (!insuranceProvidersList) {
    return <Loader />
  }

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12} md={10}>
        <Title>Submit Insurance Information</Title>
        <InsuranceForm
          patient={patientInfo}
          providers={insuranceProvidersList}
          onSubmit={submitInsurance}
        />
      </Grid>
    </Grid>
  )
}

export default AddInsurance
