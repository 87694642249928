import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import Title from '../base/Title'

interface Props {
  title: string
  message: string
  buttonMessage: string
  goBack: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paragraph: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  })
)

const FormMessage = ({ title, message, buttonMessage, goBack }: Props) => {
  const classes = useStyles()
  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justifyContent="center">
      <Grid item xs={12} sm={6}>
        <div>
          <Title>{title}</Title>
          <Typography component="p" className={classes.paragraph}>
            {message}
          </Typography>
          <Button
            onClick={goBack}
            variant="contained"
            color="primary"
            fullWidth>
            {buttonMessage ? buttonMessage : 'Continue'}
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

export default FormMessage
