import { useLocation, useHistory } from 'react-router-dom'
import { PatientInfo } from '../lib/types/Patient'
import PatientDetailWrapper from '../containers/PatientDetailWrapper'
import useTitle from 'src/hooks/useTitle'
import { useEffect, useState } from 'react'
import useStripeApi from 'src/hooks/useStripe'
import { getOrthoImages, getPatientsList, patchPatient } from 'src/api/prime'
import { createStripeCustomer } from 'src/api/stripe'
import useNotify from 'src/components/banner/useNotify'

const PatientDetail = () => {
  const location = useLocation()
  const history = useHistory()
  const { setTitle } = useTitle()
  const [isPatientUpdated, setIsPatientUpdated] = useState<boolean>(false)
  const notify = useNotify()

  if (!location.state) {
    history.push('/search')
  }

  const [patientInfo, setPatientInfo] = useState(
    location.state['patientInfo'] as PatientInfo
  )
  const { patientCharges, patientStripeInfo, getStripeCharges } = useStripeApi(
    patientInfo.person.stripe_customer_id
  )
  const [patientOrthoPhotos, setPatientOrthoPhotos] = useState([])
  const patientName = `${patientInfo.person.first_name} ${patientInfo.person.last_name}`

  const setStripeCustomerId = async () => {
    try {
      const stripeCustomer = await createStripeCustomer(patientInfo)
      await patchPatient(patientInfo.id, {
        stripe_customer_id: stripeCustomer.id,
      })
      setIsPatientUpdated(false)
    } catch (error) {
      notify(error.message, 'error')
    }
  }

  useEffect(() => {
    const getPatientOrthoImages = async () => {
      const photos = await getOrthoImages(patientInfo.id)
      setPatientOrthoPhotos(photos)
    }

    if (!patientInfo?.id) {
      return
    }

    getPatientOrthoImages()
  }, [patientInfo?.id])

  useEffect(() => {
    if (patientCharges) return

    const {
      person: {
        email,
        first_name: firstName,
        last_name: lastName,
        primary_phone: phone,
        stripe_customer_id: stripeCustomerId,
      },
    } = patientInfo

    const shouldCreateStripeCustomer =
      /**
       * Should create customer if we don't already have one, but must have email/first/last/phone in order to do so.
       * If we can't create a customer and one doesn't exist, payment interface will not be present in the UI.
       */
      !stripeCustomerId && email && firstName && lastName && phone

    if (shouldCreateStripeCustomer) {
      setStripeCustomerId()
    }

    getStripeCharges()
    // eslint-disable-next-line
  }, [patientCharges, getStripeCharges])

  useEffect(() => {
    setTitle('Lookup Member')
    // eslint-disable-next-line
  }, [patientName])

  useEffect(() => {
    if (isPatientUpdated) return

    const updatePatientInfo = () => {
      getPatientsList({ chart_number: patientInfo.chart_number, page: 1 }).then(
        ({ results }) => {
          // Ensure we get the correct patient if multiple are returned
          const patient = results.find(
            (p) => p.chart_number === patientInfo.chart_number
          )
          setPatientInfo(patient)
          setIsPatientUpdated(true)
        }
      )
    }

    updatePatientInfo()
  }, [isPatientUpdated, patientInfo.chart_number])

  return (
    <PatientDetailWrapper
      patientInfo={patientInfo}
      patientPaymentInfo={patientStripeInfo}
      patientCharges={patientCharges}
      patientOrthoPhotos={patientOrthoPhotos}
    />
  )
}

export default PatientDetail
