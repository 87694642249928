import { Controller, Control } from 'react-hook-form'
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'

interface SelectInputOption {
  id: string
  label: string
}

interface SelectInputProps {
  name: string
  control: Control<any>
  defaultValue?: string
  label: string
  options: SelectInputOption[]
  [key: string]: any
}

const SelectInput = ({
  name,
  control,
  defaultValue = '',
  label,
  options,
  ...other
}: SelectInputProps) => {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field, formState: { errors } }) => (
        <FormControl error={!!errors[name]} {...other}>
          <InputLabel id={`${name}-label-id`}>{label}</InputLabel>
          <Select labelId={`${name}-label-id`} id={`${name}-select`} {...field}>
            {options.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors[name]?.message ?? ' '}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default SelectInput
