import { PatientInfo } from 'src/lib/types/Patient'
import { callStripeTender } from '../prime/connector'

const createStripeCustomer = async (patientData: PatientInfo) => {
  const {
    person: { email, first_name, last_name, primary_phone: phone },
  } = patientData
  const name = `${first_name} ${last_name}`

  if (!email || !name || !phone) {
    throw new Error('Must provide an email, name, payment method, and phone')
  }

  const customer = await callStripeTender('/stripe/customers', {
    method: 'POST',
    data: {
      name,
      phone,
      email,
      payment_method: {},
    },
  })

  return customer
}

export default createStripeCustomer
