import { Grid } from '@material-ui/core'
import {
  AddCircle as AddPatientIcon,
  Search as SearchIcon,
} from '@material-ui/icons'
import DashboardItem from 'src/components/DashboardItem'

const isCrmEnabled = process.env.REACT_APP_ENABLE_EMERGENCY_CRM === 'true'

const Home = () => {
  return (
    <>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {isCrmEnabled && (
          <DashboardItem linkTo="/add-patient">
            <AddPatientIcon />
            <span>Add Patient</span>
          </DashboardItem>
        )}
        <DashboardItem linkTo="/search">
          <SearchIcon />
          <span>Search</span>
        </DashboardItem>
      </Grid>
    </>
  )
}

export default Home
