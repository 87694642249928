import { Stripe as StripeJs } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'

let stripePromise: Promise<StripeJs | null>
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY!)
  }
  return stripePromise
}

export default getStripe
