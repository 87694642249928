import { alpha, createTheme } from '@material-ui/core'
import { defaultsDeep } from 'lodash'
import { breakpoints, colors, fonts, zIndexes } from './theme'

const { nantes, nantesBold, foundersGrotesk, foundersMedium } = fonts

const theme = createTheme({
  breakpoints,
})

// Create a theme instance.
export const lightTheme = createTheme({
  breakpoints,
  palette: {
    type: 'light',
    primary: {
      main: colors.tints.green[6],
      contrastText: colors.primary.grey[0],
    },
    secondary: {
      main: colors.primary.copper[1],
      contrastText: colors.primary.grey[0],
    },
    error: {
      main: '#d76d58',
    },
    text: {
      primary: colors.primary.copper[1],
      secondary: '#5e7872',
    },
    background: {
      default: colors.primary.grey[0],
    },
    common: {
      white: colors.primary.grey[0],
    },
  },
  typography: {
    fontFamily: 'Founders Grotesk',
    h1: {
      fontFamily: 'Nantes',
      lineHeight: 1.2,
      fontWeight: 'bold',
      fontSize: 64,
      [theme.breakpoints.up('md')]: {
        fontSize: 96,
      },
    },
    h2: {
      fontFamily: 'Nantes',
      lineHeight: 1.2,
      fontWeight: 'bold',
      fontSize: 48,
      [theme.breakpoints.up('md')]: {
        fontSize: 56,
      },
    },
    h3: {
      fontFamily: 'Nantes',
      lineHeight: 1.2,
      fontWeight: 'bold',
      fontSize: 32,
      [theme.breakpoints.up('md')]: {
        fontSize: 48,
      },
    },
    h4: {
      fontFamily: 'Nantes',
      lineHeight: 1.2,
      fontWeight: 'bold',
      fontSize: 24,
      [theme.breakpoints.up('md')]: {
        fontSize: 32,
      },
    },
    h5: {
      fontFamily: 'Nantes',
    },
    body1: {
      fontSize: 20,
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
    },
    body2: {
      fontSize: 20,
      [theme.breakpoints.up('md')]: {
        fontSize: 24,
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [foundersGrotesk, foundersMedium, nantes, nantesBold],
        html: {
          '-webkit-font-smoothing': 'auto',
          '-moz-osx-font-smoothing': 'auto',
          'font-variant-ligatures': 'discretionary-ligatures',
          '-webkit-overflow-scrolling': 'touch',
        },
        // Hides blue ring around carousels on Safari/FF
        '.slider:focus': {
          outlineColor: 'transparent',
          outlineStyle: 'none',
        },
        '.slider-slide:focus': {
          outlineColor: 'transparent',
          outlineStyle: 'none',
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.primary.grey[0],
        color: colors.tints.grey[4],
        borderBottom: `1px solid ${colors.primary.grey[1]}`,
        boxShadow: 'none',
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none !important',
        fontWeight: 500,
        letterSpacing: '0.12em',
        borderRadius: '100px',
        padding: '12px 32px',
        '&:hover': {
          textDecoration: 'none',
        },
        '&.Mui-focusVisible': {
          outline: '-webkit-focus-ring-color auto 5px',
        },
      },
      sizeLarge: {
        fontSize: 18,
        padding: '16px 40px',
      },
      containedPrimary: {
        color: colors.primary.grey[0],
        backgroundColor: '#0068b5',
        '&:hover': {
          color: '#ffece9',
          backgroundColor: '#0060a7',
          '@media (hover: none)': {
            color: '#ffece9',
            backgroundColor: '#0060a7',
          },
        },
        '&:active': {
          color: '#ffb6a8',
          backgroundColor: '#00589a',
        },
        '&:disabled, &.Mui-disabled': {
          color: alpha(colors.tints.grey[7], 0.3),
          backgroundColor: alpha(colors.tints.grey[7], 0.1),
        },
      },
      containedSecondary: {
        color: colors.primary.grey[0],
        backgroundColor: '#bc8452',
        '&:hover': {
          color: '#fff',
          backgroundColor: '#9c6c41',
          '@media (hover: none)': {
            color: '#fff',
            backgroundColor: '#9c6c41',
          },
        },
        '&:active': {
          color: '#ffded9',
          backgroundColor: '#7b5431',
        },
      },
      outlinedPrimary: {
        color: colors.primary.blue[0],
        border: `1px solid ${colors.primary.blue[0]}`,
        '&:hover': {
          color: colors.primary.grey[0],
          backgroundColor: '#0060a7',
          borderColor: '#0060a7',
          '@media (hover: none)': {
            color: colors.primary.grey[0],
            backgroundColor: '#0060a7',
            borderColor: '#0060a7',
          },
        },
        '&:active': {
          color: '#ffb6a8',
          backgroundColor: '#00589a',
        },
        '&:disabled, &.Mui-disabled': {
          color: alpha(colors.tints.grey[7], 0.3),
          border: `1px solid ${alpha(colors.tints.grey[7], 0.1)}`,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: alpha(colors.primary.copper[1], 0.5),
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
      },
    },
    MuiRadio: {
      root: {
        '&.Mui-focusVisible': {
          outline: '-webkit-focus-ring-color auto 5px',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 15,
        color: colors.primary.grey[0],
        lineHeight: 1.2,
        [theme.breakpoints.up('md')]: {
          fontSize: 18,
        },
      },
    },
    MuiFormLabel: {
      colorSecondary: {
        color: colors.primary.copper[1],
      },
    },
    MuiFormControlLabel: {
      root: {
        fontSize: 20,
      },
    },
    MuiInput: {
      root: {
        color: colors.primary.copper[1],
        '&:disabled, .Mui-disabled': {
          color: colors.primary.copper[1],
          opacity: 0.3,
        },
      },
      input: {
        fontSize: 20,
        '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
          {
            border: 'none',
            '-webkit-text-fill-color': colors.primary.copper[1],
            '-webkit-box-shadow': '0 0 0px 1000px transparent inset',
            transition: 'background-color 5000s ease-in-out 0s',
          },
      },
      underline: {
        '&:after': {
          borderBottomColor: colors.primary.copper[1],
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 20,
        color: alpha(colors.primary.copper[1], 0.5),
        '&.Mui-focused': {
          color: colors.primary.copper[1],
        },
        '&:disabled, &.Mui-disabled': {
          color: colors.primary.copper[1],
        },
      },
      shrink: {
        color: colors.primary.copper[1],
        zIndex: zIndexes.standard,
      },
    },
    MuiLinearProgress: {
      root: {
        height: 8,
      },
      colorPrimary: {
        backgroundColor: 'transparent',
      },
      barColorPrimary: {
        backgroundColor: colors.primary.blue[0],
      },
    },
    MuiLink: {
      root: {
        color: '#0068b5',
      },
      underlineNone: {
        color: colors.primary.grey[0],
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: colors.tints.blue[4],
          backgroundColor: 'unset',
          borderBottomColor: colors.tints.blue[4],
          '& h4, p, svg, span, input': {
            color: colors.tints.blue[4],
          },
          '& circle, path': {
            stroke: colors.tints.blue[4],
          },
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },
      button: {
        color: colors.primary.copper[1],
        borderBottomColor: colors.primary.grey[1],
        '&:hover': {
          color: colors.tints.blue[4],
          backgroundColor: 'unset',
          borderBottomColor: colors.tints.blue[4],
          '& h4, p, svg, span, input': {
            color: colors.tints.blue[4],
          },
        },
        '&:active': {
          color: colors.tints.blue[6],
          borderBottomColor: colors.tints.blue[6],
          '& h4, p, svg, span, input': {
            color: colors.tints.blue[6],
          },
        },
      },
      divider: {
        borderBottomColor: colors.primary.grey[1],
      },
    },
    MuiListItemIcon: {
      root: {
        alignItems: 'center',
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 0,
      },
      list: {
        backgroundColor: colors.primary.blue[0],
      },
    },
    MuiMenuItem: {
      root: {
        color: colors.tints.grey[0],
        borderRadius: 0,
        '&:hover': {
          backgroundColor: colors.tints.grey[0],
          color: colors.primary.blue[0],
        },
        '&$selected': {
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: colors.tints.grey[0],
            color: colors.primary.blue[0],
          },
        },
      },
      gutters: {
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: colors.tints.grey[1],
        },
      },
      icon: {
        color: 'currentColor',
      },
    },
    MuiSnackbarContent: {
      root: {
        borderRadius: 0,
        backgroundColor: colors.tints.grey[7],
      },
      message: {
        fontSize: 16,
        textTransform: 'uppercase',
        letterSpacing: '0.12em',
        fontWeight: 500,
      },
    },
    MuiAutocomplete: {
      listbox: {
        backgroundColor: '#0068b5',
      },
      option: {
        backgroundColor: '#0068b5',
        color: colors.primary.grey[0],
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&[data-focus="true"]': {
          backgroundColor: colors.primary.grey[0],
          color: '#0068b5',
        },
      },
      paper: {
        borderRadius: 0,
        margin: 0,
      },
      tag: {
        borderRadius: 0,
        fontSize: 20,
      },
    },
  },
} as any)

export const darkTheme = createTheme(
  defaultsDeep(
    {
      palette: {
        type: 'dark',
        text: {
          primary: colors.primary.grey[0],
          secondary: '#efede9',
        },
        error: {
          main: '#ffcac0',
        },
        background: {
          default: '#5c7872',
        },
      },
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: colors.tints.green[7],
          },
        },
        MuiButton: {
          containedPrimary: {
            color: colors.primary.blue[0],
            backgroundColor: colors.primary.grey[0],
            '&:hover': {
              color: '#0068B5',
              backgroundColor: colors.defaults.white,
              '@media (hover: none)': {
                color: '#0068B5',
                backgroundColor: colors.defaults.white,
              },
            },
            '&:active': {
              color: '#00589a',
              backgroundColor: '#efede9',
            },
            '&:disabled': {
              color: '#e5e2dc',
              backgroundColor: colors.primary.grey[0],
              opacity: 0.3,
            },
          },
          outlinedPrimary: {
            color: '#F9F9F6',
            border: '1px solid #F9F9F6',
            '&:hover': {
              color: '#0068B5',
              backgroundColor: '#fff',
              '@media (hover: none)': {
                color: '#0068B5',
                backgroundColor: '#fff',
              },
            },
            '&:active': {
              color: '#00589a',
              backgroundColor: '#efede9',
            },
          },
        },
        MuiLink: {
          underlineNone: {
            color: colors.primary.blue[0],
          },
        },
        MuiInput: {
          root: {
            color: colors.primary.grey[0],
            '&:disabled, .Mui-disabled': {
              color: colors.primary.grey[0],
              opacity: 0.3,
            },
          },
          input: {
            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus':
              {
                '-webkit-text-fill-color': colors.primary.grey[0],
              },
          },
          underline: {
            '&:after': {
              borderBottomColor: colors.primary.grey[0],
            },
          },
        },
        MuiInputLabel: {
          root: {
            fontSize: 20,
            color: alpha(colors.primary.grey[0], 0.5),
            '&.Mui-focused': {
              color: colors.primary.grey[0],
            },
            '&:disabled, &.Mui-disabled': {
              color: colors.primary.grey[0],
            },
          },
          shrink: {
            color: colors.primary.grey[0],
            zIndex: zIndexes.standard,
          },
        },
        MuiCircularProgress: {
          colorPrimary: {
            color: colors.primary.grey[0],
          },
        },
        MuiMenu: {
          list: {
            backgroundColor: colors.primary.grey[0],
          },
        },
        MuiMenuItem: {
          root: {
            color: colors.tints.blue[4],
            borderRadius: 0,
            '&:hover': {
              backgroundColor: colors.tints.blue[4],
              color: colors.primary.grey[0],
            },
            '&$selected': {
              color: colors.primary.grey[0],
              backgroundColor: colors.tints.blue[3],
              '&:hover': {
                backgroundColor: colors.tints.blue[4],
                color: colors.primary.grey[0],
              },
            },
          },
        },
        MuiSelect: {
          select: {
            '&:focus': {
              backgroundColor: colors.tints.green[6],
            },
          },
        },
      },
    },
    lightTheme
  )
)

export default lightTheme
