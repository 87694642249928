import { Grid, makeStyles, Paper, Theme } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2),
    lineHeight: '1.2',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  link: {
    textDecoration: 'none',
  },
}))

const DashboardItem = ({ children, linkTo }) => {
  const classes = useStyles()

  return (
    <Grid item xs={6} sm={4} md={3} className={classes.root}>
      <Link to={{ pathname: linkTo }} className={classes.link}>
        <Paper variant="outlined" className={classes.container}>
          {children}
        </Paper>
      </Link>
    </Grid>
  )
}

export default DashboardItem
