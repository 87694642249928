import { createClient as contentfulCreateClient } from 'contentful'
import { TypeBookingFlowFields } from 'src/lib/types/contentful/bookingFlow'
import { TypeMarketFields } from 'src/lib/types/contentful/market'
import { TypeStudioFields } from 'src/lib/types/contentful/studio'

const environment = process.env.NODE_ENV

const getByKey = (key: string) => (list: unknown) => list[key]
const getByItems = getByKey('items')
const filterByKey = (key: string) => (list) =>
  list.filter((item) => item.fields[key])
const filterByBookingEnabled = filterByKey('bookingEnabled')

const config = {
  marketing: {
    spaceId: process.env.REACT_APP_CTF_SPACE_ID,
    deliveryToken: process.env.REACT_APP_CTF_DELIVERY_TOKEN,
    previewToken: process.env.REACT_APP_CTF_PREVIEW_TOKEN,
    environment: process.env.REACT_APP_CTF_ENVIRONMENT,
  },
  onboarding: {
    spaceId: process.env.REACT_APP_CTF_OB_SPACE_ID,
    deliveryToken: process.env.REACT_APP_CTF_OB_DELIVERY_TOKEN,
    previewToken: process.env.REACT_APP_CTF_OB_PREVIEW_TOKEN,
    environment: process.env.REACT_APP_CTF_OB_ENVIRONMENT,
  },
}

const createClient = (space: string = 'marketing') => {
  const options = {
    host: 'cdn.contentful.com',
    space: config[space].spaceId,
    accessToken: config[space].deliveryToken,
    environment: config[space].environment,
  }

  if (environment !== 'production') {
    options.host = 'preview.contentful.com'
    options.accessToken = config[space].previewToken
  }

  return contentfulCreateClient(options)
}

export const marketingClient = createClient('marketing')
export const onboardingClient = createClient('onboarding')

export const getStudiosFromContentful = async () => {
  const studioEntries = await marketingClient.getEntries<TypeStudioFields>({
    content_type: 'studio',
    select: [
      'fields.name',
      'fields.slug',
      'fields.market',
      'fields.serviceCodes',
      'fields.address',
      'fields.location',
      'fields.openingDate',
      'fields.bookingEnabled',
    ],
    'fields.virtual': false,
  })

  return filterByBookingEnabled(getByItems(studioEntries))
}

export const getMarketsFromContentful = async () => {
  const marketEntries = await marketingClient.getEntries<TypeMarketFields>({
    content_type: 'market',
    select: [
      'fields.name',
      'fields.slug',
      'fields.location',
      'fields.bookingEnabled',
      'fields.recareEnabled',
    ],
  })
  return filterByBookingEnabled(getByItems(marketEntries))
}

export const getOnBoardingClient = async () => {
  const onboardingEntries =
    await onboardingClient.getEntry<TypeBookingFlowFields>(
      process.env.REACT_APP_CTF_OB_ENTRIES_BOOKING
    )

  return onboardingEntries
}

export const getServicesFromContentful = async () => {
  const {
    fields: { services },
  } = await getOnBoardingClient()
  return services
}
