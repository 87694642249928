import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getStripeCustomer, paymentIntents } from 'src/api/stripe'
import chargeInStripe from 'src/api/stripe/createStripeCharge'
import Title from 'src/components/base/Title'
import FormMessage from 'src/components/formBase/FormMessage'
import ChargeForm from 'src/components/forms/ChargeForm'
import Loader from 'src/components/Loader'
import { useGlobalContext } from 'src/context'
import useTitle from 'src/hooks/useTitle'
import { PatientInfo } from 'src/lib/types/Patient'
import { toUSDString } from 'src/utils'

const serializeCreditCardName = (creditCardList) =>
  creditCardList.map((cardInfo) => ({
    ...cardInfo,
    name: `${cardInfo.card.brand} - ${cardInfo.card.last4}`,
  }))

const AddPayment = () => {
  const location = useLocation()
  const history = useHistory()
  const {
    state: { studiosList },
    setProgressBar,
    hideProgressBar,
  } = useGlobalContext()
  const { setTitle } = useTitle()
  const [isPaymentAccepted, setIsPaymentAccepted] = useState<boolean>(false)
  const [confirmationMessage, setConfirmationMessage] = useState(null)
  const [patientPaymentInfo, setPatientPaymentInfo] = useState(null)
  const [paymentInfo, setPaymentInfo] = useState(null)
  let patientInfo: PatientInfo

  if (!location.state) {
    history.push('/patients')
  }

  patientInfo = location.state['patientInfo'] as PatientInfo
  const currentPaymentInfo = JSON.parse(location.state['cardSelected']).id

  const patientName = `${patientInfo.person.first_name} ${patientInfo.person.last_name}`

  const submitPayment = async (paymentData) => {
    const paymentIntent = await paymentIntents(
      paymentData.amount,
      patientInfo.person.stripe_customer_id
    )

    const currentPayment = patientPaymentInfo.find(
      (paymentItem) => paymentItem.id === paymentData.paymentMethod
    )

    const chargePayment = await chargeInStripe({
      clientSecret: paymentIntent.client_secret,
      paymentId: currentPayment.id,
    })

    if (chargePayment.paymentIntent) {
      setConfirmationMessage({
        title: 'Success',
        message: `Total: ${toUSDString(
          chargePayment.paymentIntent.amount / 100
        )}
        \n${currentPayment.card.brand}
        \n${paymentData.chargeCategory} - ${paymentData.chargeDescription}
        `,
      })
      setIsPaymentAccepted(true)
    } else if (chargePayment.error) {
      setConfirmationMessage({
        title: 'Success',
        message: `Transaction declined`,
      })
      setIsPaymentAccepted(true)
    }
  }

  const completeForm = () => {
    history.push(`/patients/${patientInfo.chart_number}/`, { patientInfo })
    setIsPaymentAccepted(false)
    setProgressBar(0)
  }

  useEffect(() => {
    setProgressBar(50)

    return () => {
      hideProgressBar()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isPaymentAccepted) {
      setProgressBar(100)
    }
    // eslint-disable-next-line
  }, [isPaymentAccepted])

  useEffect(() => {
    setTitle(patientName)
    // eslint-disable-next-line
  }, [patientName])

  useEffect(() => {
    const getPatientInfo = async () => {
      const { data } = await getStripeCustomer(
        patientInfo.person.stripe_customer_id
      )

      setPatientPaymentInfo(data)
      setPaymentInfo(serializeCreditCardName(data))
    }

    getPatientInfo()
  }, [setPatientPaymentInfo, patientInfo])

  if (isPaymentAccepted && !!confirmationMessage) {
    return (
      <FormMessage
        title={confirmationMessage.title}
        goBack={completeForm}
        message={confirmationMessage.message}
        buttonMessage="Go back"
      />
    )
  }

  if (!studiosList || !paymentInfo) {
    return <Loader />
  }

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12} md={10}>
        <Title>Charge Card</Title>
        <ChargeForm
          onSubmit={submitPayment}
          studios={studiosList}
          paymentInfo={paymentInfo}
          currentPaymentInfo={currentPaymentInfo}
        />
      </Grid>
    </Grid>
  )
}

export default AddPayment
