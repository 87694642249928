import { useContext } from 'react'
import { Color as AlertSeverity } from '@material-ui/lab'
import { NotificationBannerContext } from './NotificationBannerContext'

export const useNotify = () => {
  const { showBanner } = useContext(NotificationBannerContext)

  const notify = (message: string, severty: AlertSeverity = 'info') => {
    showBanner(message, severty)
  }

  return notify
}

export default useNotify
