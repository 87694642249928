import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import getApiAuthManager from './api-auth-manager'

const attachAuthInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(async (config) => {
    if (!config.headers.common.Authorization) {
      const authManager = await getApiAuthManager()
      const token = authManager.getIdToken()
      if (token) {
        config.headers.common.Authorization = `Bearer ${token}`
      }
    }
    return config
  })
}

const getApikey = () => process.env.REACT_APP_TEND_STRIPE_TENDER_KEY

const attachApikeyInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    if (!config.headers.common['x-api-key']) {
      const apiKey = getApikey()

      if (apiKey) {
        config.headers.common['x-api-key'] = apiKey
      }
    }

    return config
  })
}

const attachErrorInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (config) => {
      return config
    },
    (error) => {
      if (error.response?.status >= 500) {
        // TODO handole errors on
      }
      return Promise.reject(error.response)
    }
  )
}

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_TEND_API_URL,
})
attachAuthInterceptor(apiInstance)
attachErrorInterceptor(apiInstance)

export const callApi = async (url: string, config: AxiosRequestConfig = {}) => {
  const { data } = await apiInstance(url, config)
  return data
}

const stripeTenderInstance = axios.create({
  baseURL: process.env.REACT_APP_TEND_STRIPE_TENDER_URL,
})

attachApikeyInterceptor(stripeTenderInstance)
attachErrorInterceptor(stripeTenderInstance)

export const callStripeTender = async (
  url: string,
  config: AxiosRequestConfig = {}
) => {
  const { data } = await stripeTenderInstance(url, config)
  return data
}
