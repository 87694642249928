import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { colors } from 'src/theme'
import clsx from 'clsx'

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      margin: theme.spacing(4, 0, 2),
      color: colors.primary.copper[0],
    },
  })
)

interface Props {
  children: React.ReactNode
  aditionalClass?: string
}

const Title = ({ children, aditionalClass }: Props) => {
  const classes = useStyles()
  return (
    <Typography
      variant="h3"
      display="block"
      className={clsx(classes.title, aditionalClass)}>
      {children}
    </Typography>
  )
}

export default Title
