import { CssBaseline, ThemeProvider } from '@material-ui/core'
import ReactDOM from 'react-dom'
import App from './App'
import { GlobalContextProvider } from './context'
import reportWebVitals from './reportWebVitals'
import theme from './themeConfig'

const mainElement = document.createElement('div')
document.body.appendChild(mainElement)

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalContextProvider>
      <CssBaseline />
      <App />
    </GlobalContextProvider>
  </ThemeProvider>,
  mainElement
)

reportWebVitals()
