import AddCreditCard from './pages/AddCreditCard'
import AddInsurance from './pages/AddInsurance'
import AddPatient from './pages/AddPatient'
import AddPayment from './pages/AddPayment'
import { Amplify } from 'aws-amplify'
import awsConfig from './aws-exports'
import ChargeList from './pages/ChargeList'
import Confirm from './pages/Confirm'
import Home from './pages/Home'
import Loader from './components/Loader'
import NavBar from './components/navBar'
import NotificationBanner from './components/banner/NotificationBanner'
import NotificationProvider from './components/banner/NotificationBannerContext'
import PatientImages from './pages/PatientImages'
import PatientDetail from './pages/PatientDetail'
import PaymentList from './pages/PaymentList'
import Search from './pages/Search'
import ServiceSelector from './pages/ServiceSelector'
import useAuthenticator from './hooks/useAuthenticator'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { colors } from './theme'
import { Container, createStyles, makeStyles } from '@material-ui/core'
import { useEffect } from 'react'
import { useGlobalContext } from './context'
import LoginWithGoogle from './components/auth/LoginWithGoogle'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

const ENV = process.env.REACT_APP_VERCEL_ENV || 'production'

/*
NOTE: URLs must be added to Cognito and match exactly in these mappings.
Be aware that if you need to modify the redirect URL for a preview branch,
the dev environment must also be set up to use that redirect URL.
*/
const redirectUrlMap = {
  preview: 'https://emergen-crm-git-develop-tend.vercel.app/',
  development: 'https://emergen-crm-git-develop-tend.vercel.app/',
  production: 'https://emergen-crm.hellotend.com/',
}
const authRedirectUrl = isLocalhost
  ? 'http://localhost:3000/'
  : redirectUrlMap?.[ENV]

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: authRedirectUrl,
    redirectSignOut: authRedirectUrl,
  },
}

Amplify.configure(updatedAwsConfig)

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      backgroundColor: colors.primary.grey[0],
      height: 'calc(100vh - 75px)',
      paddingTop: '15px',
    },
  })
)

const App = () => {
  const { fetchInsuranceProviders, fetchStudios } = useGlobalContext()
  const classes = useStyles()
  const { state: userState, signOut, signInWithGoogle } = useAuthenticator()

  useEffect(() => {
    fetchInsuranceProviders()
    fetchStudios()
    // eslint-disable-next-line
  }, [])

  if (userState.isLoading) {
    return <Loader />
  }

  if (!userState.user) {
    return (
      <Router>
        <Switch>
          <Route path="/">
            <LoginWithGoogle
              signInWithGoogle={signInWithGoogle}
              errorMessage={userState.errorMessage}
            />
          </Route>
        </Switch>
      </Router>
    )
  }

  return (
    <NotificationProvider>
      <Router>
        <NavBar isLogIn={!!userState.user} signOut={signOut} />
        <Container component="main" maxWidth="xl" className={classes.container}>
          <Switch>
            <Route path="/patients/:id/images">
              <PatientImages />
            </Route>
            <Route path="/patients/:id/add-card">
              <AddCreditCard />
            </Route>
            <Route path="/patients/:id/payment-list">
              <PaymentList />
            </Route>
            <Route path="/patients/:id/create-payment">
              <AddPayment />
            </Route>
            <Route path="/patients/:id/charge-list">
              <ChargeList />
            </Route>
            <Route path="/patients/:id/submit-insurance">
              <AddInsurance />
            </Route>
            <Route path="/patients/:id/services">
              <ServiceSelector />
            </Route>
            <Route path="/patients/:id/booking/confirm">
              <Confirm />
            </Route>
            <Route path="/patients/:id">
              <PatientDetail />
            </Route>
            <Route path="/add-patient">
              <AddPatient />
            </Route>
            <Route path="/search">
              <Search />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Container>
      </Router>
      <NotificationBanner />
    </NotificationProvider>
  )
}

export default App
