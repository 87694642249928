import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useNotify from 'src/components/banner/useNotify'
import Title from 'src/components/base/Title'
import AddCard from 'src/components/forms/AddCard'
import Loader from 'src/components/Loader'
import { useGlobalContext } from 'src/context'
import useTitle from 'src/hooks/useTitle'
import { PatientInfo } from 'src/lib/types/Patient'
import { hasElements } from 'src/utils'

const AddCreditCard = () => {
  const location = useLocation()
  const history = useHistory()
  const [isCreditCardAdded, setIsCreditCardAdded] = useState<boolean>(false)
  let patientInfo: PatientInfo
  const {
    state: { insuranceProvidersList },
    setProgressBar,
    hideProgressBar,
  } = useGlobalContext()
  const { setTitle } = useTitle()
  const notify = useNotify()

  if (!location.state) {
    history.push('/patients')
  }

  patientInfo = location.state['patientInfo'] as PatientInfo
  const {
    person: { first_name, last_name },
  } = patientInfo
  const patientName = `${first_name} ${last_name}`

  const submitPayment = async () => {
    setIsCreditCardAdded(true)
  }

  useEffect(() => {
    setTitle(patientName)

    // eslint-disable-next-line
  }, [patientName])

  useEffect(() => {
    setProgressBar(50)

    return () => {
      hideProgressBar()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isCreditCardAdded) {
      notify('Credit card has been added')
      history.push(`/patients/${patientInfo.chart_number}/`, { patientInfo })
    }
    // eslint-disable-next-line
  }, [isCreditCardAdded])

  if (!hasElements(insuranceProvidersList)) {
    return <Loader />
  }

  return (
    <Grid container spacing={3} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={12} md={10}>
        <Title>Add Credit Card</Title>
        <AddCard onSubmit={submitPayment} patientInfo={patientInfo} />
      </Grid>
    </Grid>
  )
}

export default AddCreditCard
