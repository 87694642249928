import { SvgIcon } from '@material-ui/core'

const DinersIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 30">
    <path
      d="M38.1818 0H20H1.8182C0.814063 0 0 0.814063 0 1.8182V27.7923C0 28.7964 0.814063 29.6105 1.8182 29.6105H20H38.1818C39.1859 29.6105 40 28.7964 40 27.7923V1.8182C40 0.814063 39.1859 0 38.1818 0Z"
      fill="#DEDEE0"
    />
    <path
      d="M13.7019 14.792C13.7052 16.6636 14.8711 18.259 16.5217 18.893V10.6875C14.8711 11.3257 13.7052 12.9211 13.7019 14.792Z"
      fill="#3D6DEB"
    />
    <path
      d="M27.4839 8.99902C25.9281 7.56668 23.8013 6.74512 21.6417 6.74512H20H18.0723C13.4443 6.74512 9.95447 10.2379 9.95447 14.8697C9.95447 19.2782 13.5822 22.8649 18.0413 22.8649H20H21.6393L21.6791 22.865C21.6792 22.865 21.6793 22.865 21.6795 22.865C23.7898 22.865 25.9111 22.0116 27.4992 20.5237C29.1413 18.9853 30.0456 16.9774 30.0456 14.8699C30.0456 12.6048 29.1358 10.5199 27.4839 8.99902ZM20 20.9532C19.3978 21.1399 18.7585 21.2407 18.0958 21.2407C14.5399 21.2407 11.647 183476 11.647 14.7914C11.647 11.2353 14.5399 8.34215 18.0958 8.34215C18.7585 8.34215 19.3978 8.44301 20 8.62965C22.6277 9.44418 24.5418 11.8984 24.5418 14.7914C24.5418 17.6845 22.6277 20.1386 20 20.9532Z"
      fill="#3D6DEB"
    />
    <path
      d="M20.0001 10.8326C19.8915 10.7801 19.781 10.731 19.6678 10.6875V18.893C19.7812 18.8495 19.8916 18.8005 20.0001 18.7482C21.4724 18.0378 22.4873 16.5352 22.4905 14.7921C22.4873 13.0497 21.4724 11.5441 20.0001 10.8326Z"
      fill="#3D6DEB"
    />
    <path
      d="M20 8.6293C19.3978 8.44266 18.7585 8.3418 18.0958 8.3418C14.5399 8.3418 11.647 11.235 11.647 14.7911C11.647 18.3472 14.5399 21.2404 18.0958 21.2404C18.7585 21.2404 19.3978 21.1395 20 20.9529C22.6277 20.1384 24.5418 17.6843 24.5418 14.7911C24.5418 11.8979 22.6277 9.44391 20 8.6293ZM16.5217 18.8923C14.8712 18.2584 13.7053 16.6628 13.7019 14.7913C13.7052 12.9204 14.8712 11.325 16.5217 10.6867V18.8923ZM20 18.7474C19.8914 18.7998 19.781 18.8488 19.6678 18.8923V10.6866C19.7811 10.7302 19.8915 10.7794 20 10.8317C21.4723 11.5432 22.4872 13.0488 22.4904 14.7913C22.4872 16.5345 21.4723 18.037 20 18.7474Z"
      fill="#DEDEE0"
    />
    <path
      d="M18.0413 22.8651C13.5822 22.8651 9.95445 19.2784 9.95445 14.8699C9.95445 10.2382 13.4443 6.74531 18.0723 6.74531H20V0H1.8182C0.814063 0 0 0.814063 0 1.8182V27.7923C0 28.7964 0.814063 29.6105 1.8182 29.6105H20V22.865L18.0413 22.8651Z"
      fill="#C3C3C7"
    />
  </SvgIcon>
)

export default DinersIcon
