import { Button } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Insurance, InsuranceProvider } from '../../lib/types/Insurance'
import SelectInput from '../formBase/SelectInput'
import TextInput from '../formBase/TextInput'
import RadioGroup from '../formBase/RadioGroup'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { selectInputMapper } from './mappers'
import { format } from 'date-fns'
import { maxCharacterLengthValidationErrorMessage } from 'src/utils'
import { PatientInfo } from 'src/lib/types/Patient'

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
}))

const schema = yup.object().shape({
  member_dob: yup.string().required(),
  member_first_name: yup
    .string()
    .max(100, maxCharacterLengthValidationErrorMessage(100))
    .required(),
  member_last_name: yup
    .string()
    .max(100, maxCharacterLengthValidationErrorMessage(100))
    .required(),
  policyholder_first_name: yup
    .string()
    .max(100, maxCharacterLengthValidationErrorMessage(100)),
  policyholder_last_name: yup
    .string()
    .max(100, maxCharacterLengthValidationErrorMessage(100)),
  policyholder_dob: yup.string(),
  policyholder_member_id: yup.string(),
  member_id: yup
    .string()
    .max(36, maxCharacterLengthValidationErrorMessage(36))
    .required(),
  policyholder_is_patient: yup.boolean().required(),
  payer: yup.string().required(),
})

interface InsuranceFormProps {
  patient: PatientInfo
  providers: InsuranceProvider[]
  onSubmit: (data: Insurance) => void
}

const convertToDate = (dateStr: string) => {
  // Convert date string to date object with local timezone offset
  if (!dateStr) {
    return null
  }
  const date = new Date(dateStr)
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)
}

const InsuranceForm = ({
  patient,
  providers,
  onSubmit,
}: InsuranceFormProps) => {
  const classes = useStyles()

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<Insurance>({
    resolver: yupResolver(schema),
    defaultValues: {
      member_dob: patient.person.date_of_birth
        ? format(convertToDate(patient.person.date_of_birth), 'MM/dd/yyyy')
        : '',
      member_first_name: patient.person.first_name,
      member_last_name: patient.person.last_name,
      policyholder_is_patient: true,
    },
  })

  const [providerOptions] = useState(selectInputMapper(providers, 'id', 'name'))
  const isPolicyholderPatient = watch('policyholder_is_patient')

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <SelectInput
        id="payer"
        name="payer"
        control={control}
        label="Insurance Provider"
        fullWidth
        options={providerOptions}
        disabled={isSubmitting}
      />
      <RadioGroup
        name="policyholder_is_patient"
        control={control}
        label="Is Member Policyholder?"
        row
        options={[
          { value: true, label: 'Yes' },
          { value: false, label: 'No' },
        ]}
        parse={(value: string): boolean => value === 'true'}
        className={classes.input}
        disabled={isSubmitting}
      />
      <TextInput
        control={control}
        id="member_dob"
        name="member_dob"
        label="Member's Date of Birth"
        fullWidth
        type="date"
        className={classes.input}
        InputLabelProps={{ shrink: true }}
        disabled={isSubmitting}
      />
      <TextInput
        control={control}
        name="member_first_name"
        id="member_first_name"
        label="Member's First Name"
        fullWidth
        className={classes.input}
        disabled={isSubmitting}
      />
      <TextInput
        control={control}
        name="member_last_name"
        id="member_last_name"
        label="Member's Last Name"
        fullWidth
        className={classes.input}
        disabled={isSubmitting}
      />
      {!isPolicyholderPatient && (
        <>
          <TextInput
            control={control}
            id="policyholder_dob"
            name="policyholder_dob"
            label="Policyholder's Date of Brith"
            fullWidth
            type="date"
            className={classes.input}
            InputLabelProps={{ shrink: true }}
            disabled={isSubmitting}
          />
          <TextInput
            control={control}
            name="policyholder_first_name"
            id="policyholder_first_name"
            label="Policyholder Legal First Name"
            fullWidth
            className={classes.input}
            disabled={isSubmitting}
          />
          <TextInput
            control={control}
            name="policyholder_last_name"
            id="policyholder_last_name"
            label="Policyholder Legal Last Name"
            fullWidth
            className={classes.input}
            disabled={isSubmitting}
          />
        </>
      )}
      <TextInput
        control={control}
        name="member_id"
        id="member_id"
        label="Member's ID"
        fullWidth
        className={classes.input}
        disabled={isSubmitting}
      />
      {!isPolicyholderPatient && (
        <TextInput
          control={control}
          name="policyholder_member_id"
          id="policyholder_member_id"
          label="Policyholder's ID"
          fullWidth
          className={classes.input}
          disabled={isSubmitting}
        />
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}>
        Submit
      </Button>
    </form>
  )
}

export default InsuranceForm
