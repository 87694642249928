import { SvgIcon } from '@material-ui/core'

const JcbIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 40 29">
    <path
      d="M32.5761 0H5.67977H4.54573C2.03361 0 0 2.03601 0 4.54573V5.47401V20.628V28.2433H10.6825H34.3178H35.4567C37.9664 28.2433 40 26.2073 40 23.6976V22.7717V2.18195V0H32.5761ZM12.5821 7.83779V22.1688V22.7262C12.5821 24.2359 11.3595 25.4585 9.84987 25.4585H9.1704H8.99815H2.5791V20.884V6.54824V5.99318C2.5791 4.48352 3.80166 3.26096 5.31132 3.26096H5.99318H6.16305H12.5821V7.83779ZM24.7216 7.83779V22.1688V22.7262C24.7216 24.2359 23.499 25.4585 21.9894 25.4585H21.3075H21.1376H14.7186V20.884V18.0441C15.7904 19.6878 17.6948 20.7237 20.4079 20.7237C21.9678 20.7237 23.1665 20.4366 23.6976 20.1711L23.2598 17.9078C22.688 18.1375 21.7166 18.3288 20.8434 18.3288C18.2547 18.3288 16.7331 16.7091 16.7331 14.142C16.7331 11.2902 18.5202 9.90011 20.8218 9.90011C21.8482 9.90011 22.6664 10.1274 23.2574 10.3762L23.8459 8.07465C23.3315 7.80908 22.1927 7.50523 20.6902 7.50523C18.147 7.50523 15.9388 8.55554 14.7138 10.503V6.54824V5.99318C14.7138 4.48352 15.9364 3.26096 17.4436 3.26096H18.1279H18.2977H24.7168V7.83779H24.7216ZM37.1888 7.83779V22.1688V22.7262C37.1888 24.2359 35.9663 25.4585 34.4542 25.4585H33.7747H33.6049H27.1858V20.884V20.4821C27.8342 20.5754 28.8797 20.6663 30.2793 20.6663C32.8273 20.6663 34.3872 20.2476 35.3179 19.5083C36.117 18.86 36.6505 17.9484 36.6505 16.7857C36.6505 15.1325 35.5667 14.0272 34.1025 13.6276V13.5893C35.5476 13.0558 36.2151 11.9935 36.2151 10.8499C36.2151 9.68958 35.5667 8.81392 34.6743 8.33782C33.7389 7.78755 32.6551 7.61529 30.887 7.61529C29.4659 7.61529 27.9586 7.72534 27.1858 7.87368V6.54824V5.99318C27.1858 4.48352 28.4084 3.26096 29.9157 3.26096H30.5999H30.7674H37.1888V7.83779ZM31.0019 14.8645C32.4469 14.8645 33.6073 15.3789 33.6073 16.6732C33.6073 18.0202 32.4493 18.5179 31.1358 18.5179C30.6406 18.5179 30.2985 18.5179 30.0138 18.4796V14.8645H31.0019ZM30.0138 12.7878V9.79963C30.2602 9.76135 30.6214 9.72307 31.2507 9.72307C32.5641 9.72307 33.3034 10.2351 33.3034 11.2064C33.3034 12.1586 32.4852 12.7855 30.9612 12.7855L30.0138 12.7878Z"
      fill="#030104"
    />
    <path
      d="M4.11028 18.0972L3.78729 20.437C4.3591 20.6284 5.23236 20.7433 5.89986 20.7433C8.71582 20.7433 10.4647 19.4681 10.4647 15.8147V7.70898H7.57221V15.7741C7.57221 17.7742 6.8114 18.3245 5.59362 18.3245C5.02421 18.3268 4.50982 18.2311 4.11028 18.0972Z"
      fill="#030104"
    />
  </SvgIcon>
)

export default JcbIcon
