import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { scheduleAnAppoinment } from 'src/api/prime/booking'
import Title from 'src/components/base/Title'
import useTitle from 'src/hooks/useTitle'
import { PatientInfo } from 'src/lib/types/Patient'
import { colors } from 'src/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.primary.grey[0],
    },
    title: {
      paddingBottom: theme.spacing(1),
      borderBottom: `3px solid ${colors.tints.grey[7]}`,
      margin: theme.spacing(0, 0, 2),
    },
    text: {
      marginBottom: theme.spacing(2),
    },
  })
)

const Confirm = () => {
  const classes = useStyles()
  const { setTitle } = useTitle()
  const location = useLocation()
  const history = useHistory()
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)

  let patientInfo: PatientInfo

  if (!location.state) {
    history.push('/patients')
  }

  patientInfo = location.state['patientInfo'] as PatientInfo

  const currentService = location.state['currentService']
  const currentStudio = location.state['currentStudioSlug']
  const appointment = location.state['appointment']

  const patientName = `${patientInfo.person.first_name} ${patientInfo.person.last_name}`

  useEffect(() => {
    setTitle('Back to user Profile')
    // eslint-disable-next-line
  }, [])

  const confirmAppoinment = async () => {
    try {
      const res = await scheduleAnAppoinment({
        starts_at: appointment.starts_at,
        ends_at: appointment.ends_at,
        provider_id: appointment.provider_id,
        operatory_id: appointment.operatory_id,
        studio: currentStudio,
        service_type: currentService,
        patient_id: patientInfo.id,
      })

      if (res) {
        setIsConfirmed(true)
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const goToProfile = () => {
    history.push(`/patients/${patientInfo.chart_number}/`, { patientInfo })
  }

  return (
    <Grid
      container
      spacing={3}
      className={classes.root}
      justifyContent="center"
      alignItems="center">
      <Grid item xs={12} sm={12} md={10}>
        {!isConfirmed ? (
          <div>
            <Title>Confirm checkup</Title>
            <Typography variant="h4" className={classes.title}>
              Visit details
            </Typography>
            <Typography variant="body2" className={classes.text}>
              Patient Name: {patientName}
            </Typography>
            <Typography variant="body2" className={classes.text}>
              Service: {currentService}
            </Typography>
            <Typography variant="body2" className={classes.text}>
              Studio: {currentStudio}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={confirmAppoinment}>
              Confirm
            </Button>
          </div>
        ) : (
          <div>
            <Title>Appointment Confirmed</Title>
            <Typography variant="body2" className={classes.text}>
              Patient Name: {patientName}
            </Typography>
            <Typography variant="body2" className={classes.text}>
              Service: {currentService}
            </Typography>
            <Typography variant="body2" className={classes.text}>
              Studio: {currentStudio}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={goToProfile}>
              Go back
            </Button>
          </div>
        )}
      </Grid>
    </Grid>
  )
}

export default Confirm
