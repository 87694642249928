import { Button, makeStyles } from '@material-ui/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import TextInput from '../formBase/TextInput'
import SelectInput from '../formBase/SelectInput'
import { useEffect, useState } from 'react'
import { Studio } from 'src/lib/types/Studio'
import { CHARGE_OPTIONS } from './constants'
import { selectInputMapper } from './mappers'

interface Props {
  onSubmit: (data) => void
  studios: Studio[]
  paymentInfo: unknown[]
  currentPaymentInfo: string
}

interface Payment {
  studio: string
  chargeCategory: string
  chargeDescription: string
  paymentMethod: string
  amount: number
}

const schema: yup.SchemaOf<Payment> = yup.object().shape({
  studio: yup.string().required(),
  chargeCategory: yup.string().required(),
  chargeDescription: yup.string().required(),
  paymentMethod: yup.string().required(),
  amount: yup.number().required(),
})

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(1),
  },
}))

const ChargeForm = ({
  studios,
  paymentInfo,
  onSubmit,
  currentPaymentInfo,
}: Props) => {
  const classes = useStyles()

  const [studioOptions] = useState(selectInputMapper(studios, 'id', 'name'))
  const [chargeOptions] = useState(selectInputMapper(CHARGE_OPTIONS.categories))
  const [chargeDescriptionOptions, setChargeDescriptionOptions] = useState([])
  const [paymentMethod] = useState(selectInputMapper(paymentInfo, 'id', 'name'))

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      chargeCategory: '',
      paymentMethod: currentPaymentInfo,
    },
    resolver: yupResolver(schema),
  })

  const chargeCategory = watch('chargeCategory')

  useEffect(() => {
    if (!chargeCategory) return

    setChargeDescriptionOptions(
      selectInputMapper(CHARGE_OPTIONS.description[chargeCategory])
    )
  }, [chargeCategory])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SelectInput
          id="studio"
          name="studio"
          defaultValue=""
          label="Studio"
          control={control}
          fullWidth
          options={studioOptions}
          className={classes.input}
          disabled={isSubmitting}
        />
        <SelectInput
          id="paymentMethod"
          name="paymentMethod"
          defaultValue=""
          label="Payment Method"
          control={control}
          fullWidth
          options={paymentMethod}
          className={classes.input}
          disabled={isSubmitting}
        />
        <SelectInput
          id="chargeCategory"
          name="chargeCategory"
          defaultValue=""
          label="Charge Category"
          control={control}
          fullWidth
          options={chargeOptions}
          className={classes.input}
          disabled={isSubmitting}
        />
        <SelectInput
          id="chargeDescription"
          name="chargeDescription"
          defaultValue=""
          label="Charge Description"
          control={control}
          fullWidth
          options={chargeDescriptionOptions}
          className={classes.input}
          disabled={!chargeCategory || isSubmitting}
        />
        <TextInput
          name="amount"
          id="amount"
          type="number"
          step="0.1"
          label="Amount (in USD)"
          control={control}
          className={classes.input}
          disabled={isSubmitting}
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary">
          Confirm
        </Button>
      </form>
    </div>
  )
}

export default ChargeForm
